import React from "react";
import { Cards, Label, Spinner, DataGrid } from "@my-org/my-monorepo-ui-lib";
import { Message } from "../../common";
import "./FeedbackStatus.scss";
import { Asf } from "@my-org/inno-services";
import { FeedbackHeader } from "../../components";
import { Typography } from "@material-ui/core";

interface IFeedbackStatusProps{
 sessionId: string;
}
interface IFeedbackStatusState{
  data: any;
  submitted: any;
  notSubmitted: any;
  loading: boolean;
}

export class FeedbackStatus extends React.Component<IFeedbackStatusProps, IFeedbackStatusState>{
  
         state = {
           loading: true,
           data: {total:"", submitted:"", notSubmitted:"", submitPer:"", audiences:[]},
           submitted: [{name:"", email:""}],
           notSubmitted: [{name:"", email:""}], 
         }
  public componentDidMount(){
    Asf.api.get(`/session/${this.props.sessionId}/all/audience`)
    .then((res: object) => {
        this.setState({data: res},()=>{
          let submittedlist: object[]= [];
          let notSubmittedlist: object[]= [];
          this.state.data.audiences.map((result: any) => {
              let rowdata = {submitted:{},notsubmitted:{},name:{}};
               if(result.feedbackStatus === "DONE"){
               rowdata.name = result.firstName+" "+result.lastName; 
                 submittedlist.push(rowdata);
               } else{
                rowdata.name = result.firstName+" "+result.lastName; 
                notSubmittedlist.push(rowdata);
               } 
               this.setState({submitted:submittedlist, notSubmitted:notSubmittedlist, loading:false},()=>{});
            })
      }
  )}).catch((err:any) => {
      Asf.logger.log(err);
  })
  
}
    render(){
      if (this.state.loading) {
        return <Spinner className="ml-15"/> ;
      }else{
        return(
          <div className="feedbackstatuscontainer pt-4">  
               <Cards className="feedback-header-card status-heading ml-6 mr-6"><FeedbackHeader sessionId={this.props.sessionId}/></Cards>

         <div className="status-card-container display-flex mt-8 ml-6 mr-6 text-center">
         <Cards className="w-16"><Label content="Total Audience" className="status-color"></Label> <p className="data-size font-weight-bold">{this.state.data.total}</p></Cards>
         <Cards  className="w-16"><Label content="Submitted" className="status-color"></Label><p className="data-size font-weight-bold">{this.state.data.submitted}</p></Cards>
         <Cards className="w-16"><Label content="Not Submitted" className="status-color"></Label><p className="data-size font-weight-bold">{this.state.data.notSubmitted}</p></Cards>
         <Cards className="w-16"><Label content="Submission" className="status-color"></Label><p className="data-size font-weight-bold">{(this.state.data.submitPer)} %</p></Cards>
        </div>
            <div className="Status-container">
               
            <div className="Status-container-card mt-8 mr-6 ml-6">
            <div className="Status-container">
                <div className="FeedbackStatus-header button-color">
                    <h3 className="flex-equal">{Message.Submitted}</h3>
                    <h3 className="flex-equal">{Message.Not_submitted}</h3>
                </div> 
                <div className="FeedbackStatus-body">
                    <div className="FeedbackStatus-inner-body-1 flex-equal">
                    {this.state.submitted.map((value: any)=>
                             <p>{value.name}</p>
                        )}
                    </div>
                    <div className="FeedbackStatus-inner-body-2 flex-equal">
                    {this.state.notSubmitted.map((value: any)=>
                        <p>{value.name}</p>
                        )}
                    </div>    
                </div>
              </div>
              </div>
              </div>
 </div>
        );
    }
  }
}