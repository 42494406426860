import React from 'react';
import { CheckBox, Button, InputField, Cards, DataGrid, Snackbar, Spinner, DialogBox, MToolTip } from '@my-org/my-monorepo-ui-lib';
import './Inbox.scss';
import {Asf, Utils} from "@my-org/inno-services";
import { Message } from '../../common';
import {Common} from '../../common/common';
import IconButton from '@material-ui/core/IconButton';
import { FaTimes } from 'react-icons/fa';
import { MdCreate, MdDeleteSweep } from 'react-icons/md';
import DeleteIcon from '@material-ui/icons/Delete';

interface InboxState {
    column: any;
    row: any;
    suppressRowClickSelection: boolean;
    enable: boolean;
    isClicked: boolean;
    empColumn: any;
    searchValue: string;
    enableId: string;
    isOpen: boolean;
    errMessage: string;
    loading: boolean;
    open: boolean;
 }
export class Inbox extends React.Component<{}, InboxState> {
    feedbackEnable: boolean;
    isCheckBoxClicked: boolean;
    isDialogButtonClicked: boolean;    
    isDisabledButtonClicked: boolean;
    deleteSession: boolean;
    data: any;
    // searchBoxContainer = React.createRef<HTMLDivElement>();
    cellClicked: any;
    constructor(props: any) {
        super(props);
        this.deleteSession = false;
        this.feedbackEnable = true;
        this.isCheckBoxClicked = false;
        this.isDialogButtonClicked = false;
        this.isDisabledButtonClicked = false;
        this.data = {session:""};
        this.state = {
            loading: true,
            empColumn : [{
                headerName: "Session", field: "session", sortable: true, filter: true,lockPosition: true,
            }, {
                headerName: "Topic", field: "title",tooltip: (params: any) => params.data.title, sortable: false, filter: false,lockPosition: true,
            }, {
                headerName: "Presenter", field: "Presented", tooltip: (params: any) => params.data.Presented, sortable: false, filter: false,lockPosition: true,
            }, {
                headerName: "Duration", field: "duration", sortable: false, filter: false,lockPosition: true,
            },{
                headerName: "Date", field: "date", sortable: false, filter: false,tooltip: (params: any) => params.data.date,lockPosition: true,
            },{
                headerName: "Time", field: "time", sortable: true, filter: true,lockPosition: true,tooltip: (params: any) => params.data.time,
            },
        ],
            column : [{
                headerName: "Session", field: "session", sortable: true, filter: true, lockPosition: true,width:220,
            }, {
                headerName: "Topic", field: "title", tooltip: (params: any) =>  params.data.title, sortable: false, filter: false,lockPosition: true,
            }, {
                headerName: "Presenter", field: "Presented", tooltip: (params: any) => params.data.Presented, sortable: false, filter: false,lockPosition: true,width:240,
            }, {
                headerName: "Date", field: "date", sortable: true, filter: true,lockPosition: true,tooltip: (params: any) => params.data.date,
            },{
                headerName: "Time", field: "time", sortable: true, filter: true,lockPosition: true,tooltip: (params: any) => params.data.time,
            },
            {
                headerName: "Duration", field: "duration", sortable: false, filter: false,lockPosition: true,
            },{
                headerName: "Submission", field: "submission", sortable: true, filter: false,lockPosition: true,width:240,
                cellRendererFramework: (params:any) => {
                    return <a onClick={()=>this.cellClickedHandle(params.data.session)} className="inbox-submission-percentage cursor-pointer">{(params.data.submitPer).toFixed(2)}%</a>;
                },
            }, {
                headerName: "Edit", field: "Edit", sortable: true, filter: false, lockPosition: true,
                cellRendererFramework:  (params: any) => {
                    let date = new Date(`${params.data.date} ${params.data.time}`);
                    date = new Date(date.getTime() - (1000 * 60)*15 );
                    let currentDate = new Date(Date.now());
                    let className;
                    if(params.data.feedbackEnabled || (currentDate>date) ) {
                        className = "disable";
                               this.isDisabledButtonClicked = true;
                    }else {
                        className = "inbox-edit-button";
                    }
                       return (
                       <div className={className} onClick={()=>(params.data.feedbackEnabled ||(currentDate>date) )? undefined:this.buttonClickedHandle(params.data.session)} ><MdCreate/></div>);
                },
            }, {
                headerName: "Enable", field: "enable", sortable: true, filter: false, lockPosition: true,
                cellRendererFramework:  (params: any) => {
                    return <CheckBox className="inbox-checkbox p-0 ml-1 inbox-edit-button"  onChange={this.checkboxClickedHandle} checked={params.data.feedbackEnabled}/>;
                }
            },{
                headerName: "Delete", field: "Delete",lockPosition: true,
                cellRendererFramework: (params:any) => {
                    return <a onClick={()=>this.cellClickedDelete(params.data.session)} className="inbox-submission-percentage cursor-pointer"><DeleteIcon /></a>;
                },
            },

        ],
        row: [],suppressRowClickSelection: false,
        enable: false,
        isClicked: false,
        searchValue: "",
        enableId: "",
        isOpen: false,
        errMessage: "",
        open: false,
         }
    }

   async componentDidMount(){
        const resp = Utils.localstorage("get","res");
        let response;
        if (resp && resp.userType === 'HR'){
            response = await Common.getAllSession();
        } else {
            const res = "submitted"; 
            response = await Common.getSession(res);
        }
        this.setState({row: response},()=>{
            this.setState({ loading: false });
        });

    //    if(this.searchBoxContainer.current){
    //     window.onkeyup = (ev: KeyboardEvent): any => {
    //         const params: any = {
    //             searchBy: this.state.searchValue
    //         }
    //         Asf.api.get(`/session/search`, params)
    //         .then((res:any) => {     
    //         const response=  Common.setResponse(res);
    //         this.setState({row: response});
    //         }).catch((err:any) => {
    //             Asf.logger.log(err);
    //            this.setState({errMessage:err.exMessage,isOpen: true});
    //         });
    //    }
    // } 
   };              

 public render() {
        let inbox;
        const res = Utils.localstorage("get","res");
        if (this.state.loading) {
            return <Spinner className="ml-15"/> ;
        }
        else if (res && res.userType === 'HR') {
            inbox = (
                <div><div className="inbox-container-hr mt-10 mb-10">
                    <Button onClick={this.handleClick} className="primary button-color w-15 mt-3" variant="contained" btnColor="primary">{Message.Create_session_button}
                    </Button>
                    <div className="searchBoxContainer inbox-search-hr display-inlineBlock w-15 float-right">
                    <MToolTip title="Search by title & presenter" id="tooltip-1">
                        <div>
                        <InputField  className="inbox-search-hr" label="search" onChange={this.handleOnChange} value={this.state.searchValue} />
                        </div>
                    </MToolTip>
                    </div>
                    
                </div>
                    <DataGrid className="data-grid min-h-65" columns={this.state.column} loadingTemplate={"loading..."} rows={this.state.row} onRowSelect={this.onRowSelect} noRowsTemplate={Message.No_sessions_found}/> 
                </div>
            );
        } else {
            inbox = (
                <div >
                <div className="inbox-container-emp display-flex mt-6">
                    <div className="searchBoxContainer inbox-search-emp mb-10 float-right">
                        <MToolTip title="Search by title & presenter" id="tooltip">
                        <div>
                        <InputField  className="inbox-search-emp float-left" label="search" onChange={this.handleOnChange} value={this.state.searchValue}/>
                        </div>
                        </MToolTip>
                    </div>
                </div>
                    <DataGrid className="data-grid min-h-65" columns={this.state.empColumn} rows={this.state.row} loadingTemplate={"loading..."} onRowSelect={this.onRowSelect} noRowsTemplate={Message.No_sessions_found}/>
                </div>
            )
        }
        return (
            <div className="inbox-container display-flex h-90 p-6">
                {inbox}
                {this.ErrMessage()}
                {this.showDialogBox()}
            </div>
        );
    }

        private cellClickedHandle = (session:any) => {
            this.cellClicked = true;
            Asf.nav.navTo(`/feedback/status/${session}`);
        }
        private checkboxClickedHandle = (e: any) => {
            this.feedbackEnable = e.target.checked;
            this.isCheckBoxClicked = true;
            this.setState({open:true});
            
        }
        private cellClickedDelete = (session:any) => {
           this.deleteSession = true;
        }

        private buttonClickedHandle = (selectedRecord:any) => {
            this.setState({ isClicked: !this.state.isClicked});
            Asf.nav.navTo(`/feedback/create-session/${selectedRecord}`);
        }

      private onRowSelect = (selectedRows: any) => {
            let selectedRecord = selectedRows && selectedRows[0];
            if (selectedRecord) {
                const res = Utils.localstorage("get", "res");
                if (res && res.userType === "HR") {
                if (this.state.suppressRowClickSelection) {
                    Asf.nav.navTo(`/feedback/status/${selectedRecord.session}`);
                    this.setState({ suppressRowClickSelection: this.state.suppressRowClickSelection});
                } else if (this.state.isClicked) {
                    Asf.nav.navTo(`/feedback/create-session/${selectedRecord.session}`);
                    this.setState({ isClicked: this.state.isClicked});
                }
                //  else if(this.isDisabledButtonClicked){
                //     this.isDisabledButtonClicked = false;
                // }
                 else if (this.isCheckBoxClicked) {
                    this.isCheckBoxClicked = false;
                    this.data = selectedRecord;
                    this.setState({open:true});
                 }
                 else if (this.deleteSession){
                    this.data = selectedRecord;
                    this.setState({open:true});
                 }
                 else {
                    if(selectedRecord.submitPer !== 0 && !this.cellClicked){
                    Asf.nav.navTo('/feedback/report/'+selectedRecord.session);
                    this.setState({ suppressRowClickSelection: !this.state.suppressRowClickSelection});
                        
                     }else{
                         this.setState({isOpen:true,errMessage:Message.no_submitted_feedback});
                     }
                   }
                }
                else {
                    let res = Utils.localstorage("get","res");
                    let id = res && res.id;
                    Asf.nav.navTo(`/feedback/filled-feedback-form/${selectedRecord.session}/${id}`);
                }

            }
            
        }
        
   private showDialogBox = () =>{
    return <DialogBox className="feedbackform-dialogBox"
      onClick1 = {this.handleYes} onClick2 = {this.handleNo} 
      open={this.state.open} title={Message.Confirmation}
      maxWidth={"xs"} buttonClass="inbox-dialog-button ml-3 mr-1 button-color" fullWidth={true} 
      showDefaultButton={true} content={this.deleteSession? Message.delete_session :(this.feedbackEnable)?Message.Enable_message:Message.disable_message}
      defaultButtonVariant="contained" >
      </DialogBox>
  }
  private handleYes = () =>{
    this.setState({open: false});
    if(this.deleteSession){
        Asf.api.delete(`/session/${this.data.session}/delete`)
        .then(async (res: any) => {
          this.deleteSession = false;
          const response = await Common.getAllSession();
          this.setState({loading:true, row: []});
          this.setState({row: response, loading: false});

        }).catch((err: any) => {
            this.setState({errMessage:err.exMessage,isOpen: true});
        }); 
    }else{
    let rowData = this.state.row;
    const index = rowData.findIndex((x:any) => x.session === this.data.session)
    rowData[index].feedbackEnabled = this.feedbackEnable;
    
    this.setState({row : rowData, open: false }, ()=>{
        let params: any = {
            isActive: this.feedbackEnable
        }

        Asf.api.update(`/session/${this.data.session}`,null, params)
        .then(async (res: any) => {
          const response = await Common.getAllSession();
          this.setState({loading:true, row: []});
          this.setState({row: response, loading: false});

        }).catch((err: any) => {
            this.setState({errMessage:err.exMessage,isOpen: true});
        });
    });
    }
   }
  private handleNo = () =>{
      this.deleteSession = false;
      this.feedbackEnable = false;
      this.setState({open: false});
}
private handleClick = () => {
    Asf.nav.navTo('feedback/create-session');
}

 private handleOnChange = (values:string) =>{
    this.setState({searchValue:values});
    const params: any = {
        searchBy: this.state.searchValue
    }
    Asf.api.get(`/session/search`, params)
    .then((res:any) => {     
    const response=  Common.setResponse(res);
    this.setState({row: response});
    }).catch((err:any) => {
        Asf.logger.log(err);
       this.setState({errMessage:err.exMessage,isOpen: true});
    });
    
 }
 private ErrMessage = () =>{
   return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
             action={<IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>}
            open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage}/>
 } 

 private handleClose = () =>{
     this.setState({isOpen:false,errMessage:""});
 } 
}