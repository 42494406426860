import React from "react";
import { Button, InputField, Cards, DialogBox, Sidebar, Spinner, ButtonLoader, Snackbar} from "@my-org/my-monorepo-ui-lib";
import "./FeedbackForm.scss";
import { Message } from "../../common";
import { FeedbackHeader } from '../../components';
import {Asf} from "@my-org/inno-services";
import { connect } from "react-redux";
import { onSidebarCLick } from "../../redux/action";
import { Common } from "../../common/common";
import IconButton from "@material-ui/core/IconButton";
import { FaTimes } from "react-icons/fa";

export interface IFeedbackFormProps {
    currentQuestion?: number;
    isEnd?: boolean;
    disabled?: boolean;
    sessionId?:string;
    sideBarClick?: (val:string)=> any;
    count: boolean;
}
export interface FeedbackFormState {
  currentQuestion: number,
  disabled: boolean,
  isEnd: boolean,
  question: string,
  Option: string[],
  result: Array<any>,
  answer: number,
  selectedAnswer: string| number | null ,
  data: object[],
  open: boolean,
  loading: boolean,
  btnLoading: boolean;
  errMessage: string;
  isOpen: boolean;
  formDisable: boolean;
}


class FeedbackForm extends React.Component<IFeedbackFormProps, FeedbackFormState> {

    public state = { 
        currentQuestion: 0,
        formDisable: false,
        btnLoading: false,
        disabled: true,
        isOpen: false,
        isEnd: false,
        question:"",
        errMessage: "",
        Option:[],
        result: [],
        answer: 0,
        loading: true,
        selectedAnswer: "",
        data: [
          {question:[],options:[],selectedOption:""}
        ],
        open: false,
    };

    public componentDidMount() {
        Asf.api.get(`/session/feedback/quesans/all`)
        .then((res: any) => {
          let data;
          let dataFinal: object[] = [];
          console.log("ques",res);
           res.map((result:any)=>{
              data = {question:"", options: "", selectedOption: ""}
              data.question = result.question.feedbackQuestion;
              data.options = result.options;
              data.selectedOption = "";
              dataFinal.push(data);
           })
           this.setState({ data: dataFinal },()=> this.setState({loading: false}));  
         }).catch((err:any) => {
            Asf.logger.log(err);
            this.setState({loading: false});
        });
    }
    public render() {
        const { currentQuestion} = this.state;
            if (this.state.loading) {
              return <Spinner className="ml-15"/> ;
          }
             let options
             if(this.state.currentQuestion < 11){
               let data = this.state.data[currentQuestion];
            options= (
              data.options.map((options)=> (
                <Button key={options} variant="contained" btnColor="primary" className={`ui floating message options
                ${data.selectedOption === options ? "selected" : null}`}
                  onClick={() => this.submitAnswer(options, currentQuestion)}>
                  {options}
                </Button>
              ))
            )} 
             else{
         options= (<InputField className="feedbackForm-textArea" multiline={true} variant="outlined" value={this.state.data[currentQuestion].selectedOption} onChange={this.handleOnChange}
         placeholder="Optional" rowsMax={6}/>)
             }
        
             return(
                <div className="feedback-container min-h-90 display-flex mt-0">
                  <Cards className="feedback-header-card"><FeedbackHeader sessionId={this.props.sessionId}/></Cards>
                  {this.showDialogBox()}
                <div className="Feedbacks-container-2 w-76">
                {/* <span>{`Questions ${currentQuestion + 1} out of ${FeedbackQuestions.length} remaining `}</span> */}
               
                <h2>{currentQuestion + 1}. {(currentQuestion == 12)?Message.Other_comments:this.state.data && this.state.data[currentQuestion].question}</h2>
                {/* <h2>{this.state.data[0].question} </h2> */}
                {options}
                <div>
                {currentQuestion !== 0 ?
                  <Button
                    className="ui inverted button w-11"
                    variant='outlined'
                    btnColor='primary'
                    onClick={this.state.formDisable === false ? this.previousQuestionHandler:undefined}
                  >
                    Prev
                  </Button>
                //   <i className ="fas fa-arrow-left" onClick={this.previousQuestionHandler}/>
                :undefined}
                {currentQuestion < 11 && (
                  <Button
                    className="ui inverted button w-11"
                    variant='outlined'
                    btnColor='primary'
                    disabled=
                    {this.state.data[currentQuestion].selectedOption === "" ? true : false }
                    onClick={this.nextQuestionHandler}
                  >
                    {Message.Next}
                  </Button>
                )}{currentQuestion > 10 && currentQuestion < 12 && (
                  <Button
                    className="ui inverted button w-11"
                    variant='outlined'
                    btnColor='primary'
                    disabled={false}
                    onClick={this.nextQuestionHandler}
                  >
                    {Message.Next}
                  </Button>
                )}
                {currentQuestion === 12 && (
                  <Button
                    className="ui inverted button w-11"
                    variant='outlined'
                    btnColor='primary'
                    disabled={false}
                    onClick={this.state.formDisable === false ? this.submitFeedback : undefined}
                  >
                    {this.state.btnLoading?<ButtonLoader type={Common.loadingType}>{Message.loading}</ButtonLoader>:
                    Message.Submit_button}
                  </Button>
                )}
                </div>
              </div>
              {this.ErrMessage()}
            </div>
              );
    }

    private submitAnswer = (response:string, i:number) =>{
      let dataFinal = this.state.data;
      dataFinal.map((result:any,index:number)=>{
              if(i ===  index){
                result.selectedOption = response;
               }
           })
      this.setState({data:dataFinal});
    }  

    private previousQuestionHandler = () => {
      this.setState({
         currentQuestion: this.state.currentQuestion - 1
       });
    }

    private nextQuestionHandler = () => {
      this.setState({
        currentQuestion: this.state.currentQuestion + 1,
      });
    }
    private handleOnChange = (values:string) =>{
      let i = this.state.currentQuestion;
        let result = this.state.data;
        result[i].selectedOption = values;
        this.setState({data:result});
   }  

   private showDialogBox = () =>{
     return <DialogBox className="feedbackform-dialogBox" onClick1 = {this.handleYes} onClick2 = {this.handleNo}
      open={this.state.open} maxWidth={"sm"} fullWidth={true} showDefaultButton={true} title ={Message.Confirmation}
       content={Message.Dialog_Message} defaultButtonVariant="contained" buttonClass="inbox-dialog-button"> 
       </DialogBox>
   }
   private submitFeedback = () =>{
     let resultFinal = this.state.data;
     this.setState({currentQuestion:12, btnLoading:true, formDisable:true});
     resultFinal.map((result:any,index:number)=>{
           result.question = index+1;
           result.answer = result.selectedOption;
           })
      Asf.api.post(`/session/${this.props.sessionId}/feedback`,resultFinal)
      .then((res:any) => {
         if(this.props.count){
          this.setState({isOpen: true, btnLoading:false});
          //Asf.nav.navTo("/feedback");
         } else{
          this.setState({open: true, btnLoading:false});
         }
      }).catch((err:any) => {
        if(err.status)
        {
          this.setState({errMessage:err.exMessage,isOpen: true,btnLoading:false, formDisable:false});
        }
        else{
          this.setState({errMessage:Message.Err_Message,isOpen: true,btnLoading:false, formDisable:false}); 
        }
           Asf.logger.log(err);
      })
   }
   private ErrMessage = () =>{
    return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
              action={<IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>}
             open={this.state.isOpen} autoHideDuration={3000} message={this.props.count?Message.success_Message:this.state.errMessage}/>
   }
   private handleClose = () =>{
    if(this.props.count){
      Asf.nav.navTo("/feedback");
     }
    this.setState({isOpen:false,errMessage:""});
   }
   private handleYes = () =>{
    this.setState({open: false});
    Asf.nav.navTo("/active-feedback");
   }
   private handleNo = () =>{
    this.setState({open: false});
    Asf.nav.navTo("/feedback");
    this.props.sideBarClick && this.props.sideBarClick('Submitted Feedback');
   }

}

const mapDispatchToProps = (dispatch: any) => {
  return {
    sideBarClick: (value:string) => dispatch(onSidebarCLick(value))

  }
}
const mapStateToProps = (state : any) => ({
  count: state.SessionCount,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackForm);