import React from 'react';
import './Dashboard.scss';
import { Cards } from '@my-org/my-monorepo-ui-lib';
import { Message } from "../../common";

export class Dashboard extends React.Component<{},{}> {
  render() {
    return (
      <div className="container-dashboard display-flex h-90">
        <div className="calender dispaly-flex h-30">
          <Cards className="card-leaves h-50 w-25 display-flex">
              <Cards className="card-inside mt-10 ml-9 text-center h-14 w-12 button-color"><p>{Message.Pending_request}</p><p>0</p></Cards>
              <Cards className="card-inside mt-10 ml-9 text-center h-14 w-12 button-color"><p>{Message.Pending_Leaves}</p><p>4</p></Cards>
              <p className="footer-card">{Message.Leaves_card_footer}</p>
          </Cards>
        </div>
        <div className="calender dispaly-flex h-30">
        <Cards  className="card-calender h-50 w-25">
        <div className="card-body">
           <h4 className="card-title mr-2 text-center font-weight-bolder">{Message.Calender_heading}</h4>
           <div className="card-image">
           <img className="calender-img" src="https://image.freepik.com/free-vector/colorful-2019-calendar_1361-737.jpg" alt="calender"/>
        </div>
        </div>
        </Cards>
        </div>

       </div> 
    );
  }
}