import React from 'react';
import { Label, Cards} from '@my-org/my-monorepo-ui-lib';
// import './feedback-header.scss';
import { Ticket } from '../../models/Tickets';

interface ITicketHeadProps{
    ticket?: Ticket;
}
    export class TicketHeader extends React.Component<ITicketHeadProps, {}>{

    render(){
        return(
            <Cards className="create-ticket-card-detail">
            <div className="Ticket-header display-flex">
            <div className="Ticket-header-detail">  
                <div className="Ticket-header-detail-label"><Label content={`Assignee`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.assignee}</div>
            </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Assigned`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.assigned?this.props.ticket.assigned:"NA"}</div>                 
                </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Desk no`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.deskNo}</div>
            </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Asset id`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.assetId?this.props.ticket.assetId:"NA"}</div>
                 </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Status`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.status}</div>
            </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Resolution`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.resolution}</div>
            </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Created`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.created}</div>
            </div>
            <div className="Ticket-header-detail">
                <div className="Ticket-header-detail-label"><Label content={`Updated`}/></div>
                <div className="Ticket-header-detail-data">{this.props.ticket && this.props.ticket.updated}</div>
            </div>
            </div>  
            </Cards>        
        );
    }
}