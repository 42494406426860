import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Login, RegistrationForm, ForgotPassword } from "./pages";
import AppRoutes from "./AppRoutes";
// import { Asf, StoreService, Utils } from "./services";
import { Provider } from "react-redux";
import store from "./redux/store";
import {Asf, StoreService, Utils} from "@my-org/inno-services";
import "./App.scss";
import "./common/variables.scss";
import "./assets/index.scss";

const renderLoginPage = (context: any) => {
  Asf.nav.setHistory(context.history);
  const _user = Utils.localstorage("get", "res");
  if(_user){
    return <AppRoutes />
  }else{
    return <Login />;
  }
}
const renderForgetPasswordPage = (context: any) => {
  Asf.nav.setHistory(context.history);
  const _user = Utils.localstorage("get", "res");
  if(_user){
    return <AppRoutes />
  }else{
    return <ForgotPassword token={context.match.params.token}/>;
    
  }
}

function App() {
  const _user = Utils.localstorage("get", "res");
  return (
    <Provider store={store}>
      <div className="App theme-1 background-color">
        <BrowserRouter>
          <Switch>
            <Route exact path="/login"
              component={(context: any) => renderLoginPage(context)}  
            />
            <Route exact path="/forgot/:token?"
              component={(context: any) => renderForgetPasswordPage(context)}
            />
            <AppRoutes />
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
