
export const onSidebarCLick = (sideBarLabel:string) => ({
    type: 'SIDEBAR_CLICK',
      sideBarLabel
    
  })
export const onPathSelect = (path?:any,user?:any) => ({
    type: 'PATH',
    info:{
      user,path
    }
  })
  export const sessionCount = (count: boolean) => ({
    type: 'COUNT',
    count
  }) 
  // export const userName = (username: boolean) => ({
  //   type: 'username',
  //   username
  // }) 