import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Inbox, Feedbacks, FilledFeedbackForm, Profile, CreateSession, Wms_Inbox, FeedbackStatus, Dashboard, SubmittedFeedbacks, RegistrationForm, CreateTicket } from './pages';
import FeedbackForm from './pages/feedback-forms/FeedbackForm';
import {Asf, Utils} from "@my-org/inno-services";
//import {Navbar} from "./components";
import { EmpRoutes, HrRoutes } from "./common";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import ActiveInbox from "./pages/inbox/ActiveInbox";
import { connect } from "react-redux";
import { onPathSelect, onSidebarCLick } from "./redux/action";
import { UpdateTicket } from './pages/create-ticket/UpdateTicket';
import { PageNotFound } from './pages/page-not-found/pageNotFound';
//import SubmittedFeedbacks from '../src/pages/submittedFeedbacks/SubmittedFeedbacks';

const logo = "https://www.innodeed.com/wp-content/uploads/2017/11/logo_colored.png";

interface AppRoutesState {
    isSideBarOpen: boolean;
    sidebarClassName: string;
}
interface AppRoutesProps {
    onSelect?: (path: string[]) => any;
    sideBarClick?: (val:string)=> any
}
class AppRoutes extends React.Component<AppRoutesProps, AppRoutesState> {
    onSelect = (value: string[]) => {
        this.props.onSelect && this.props.onSelect(value);
    }
    sideBarClick = (value: string) => {
        this.props.sideBarClick && this.props.sideBarClick(value);
      }
    constructor(props: any) {
        super(props);
        this.state = {
            isSideBarOpen: false,
            sidebarClassName: ""
        };
    }

    renderInboxPage = (context: any, path: string) => {
        //console.log('renderInboxPage:setHistory', context.history);
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        const res = Utils.localstorage("get", "res");
        {res && res.userType === "HR" ? this.sideBarClick("Feedback System"):
        this.sideBarClick("Submitted Feedback")}
        Asf.nav.setHistory(context.history);
        return <Inbox/>
    }
    renderActiveInboxPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Active Feedback");
        Asf.nav.setHistory(context.history);
        return <ActiveInbox />
    }
    renderFeedbackPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        path1[path1.length-1] = context.match.params.sessionId;
        this.onSelect(path1);
        this.sideBarClick("Feedback System");
        Asf.nav.setHistory(context.history);
        return <Feedbacks sessionId={context.match.params.sessionId}/>
    }
    renderFeedbackFormPage = (context: any, path: string) => {
        let str = context.location.pathname;
        let path1 = str.split("/");
        this.onSelect(path1);
        Asf.nav.setHistory(context.history);
        return <FilledFeedbackForm sessionId={context.match.params.sessionId} userId={context.match.params.userId}/>
    }
    renderProvideFeedbackPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Active Feedback");
        Asf.nav.setHistory(context.history);
        return <FeedbackForm sessionId={context.match.params.sessionId}/>
    }
    renderCreateSession = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        path1[3] = context.match.params.sessionId;
        this.onSelect(path1);
        this.sideBarClick("Feedback System");
        Asf.nav.setHistory(context.history);
        return <CreateSession sessionId={context.match.params.sessionId} />
    }

    renderProfilePage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Profile");
        Asf.nav.setHistory(context.history);
        return <Profile />
    }

    renderUpdateTicket = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        path1[3] = context.match.params.ticketId;
        this.onSelect(path1);
        this.sideBarClick("Ticket System");
        Asf.nav.setHistory(context.history);
        return <UpdateTicket ticketId={context.match.params.ticketId} ticketType={context.match.params.ticketType} />
    }
    renderCreateTicket = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        path1[3] = context.match.params.ticketId;
        this.onSelect(path1);
        this.sideBarClick("Ticket System");
        Asf.nav.setHistory(context.history);
        return <CreateTicket />
    }
    renderWorkflowInboxPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Ticket System");
        Asf.nav.setHistory(context.history);
        return <Wms_Inbox />
    }
    renderFeedbackStatus = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Feedback System");
        Asf.nav.setHistory(context.history);
        return <FeedbackStatus sessionId={context.match.params.sessionId} />
    }
    renderSubmittedFeedback = (context: any, path: string) => {
        console.log("context",context.location.pathname);
        let str = context.location.pathname;
        let path1 = str.split("/");
        this.onSelect(path1);
        const res = Utils.localstorage("get", "res");
        {res && res.userType === "HR" ? this.sideBarClick("Feedback System"):
        this.sideBarClick("Submitted Feedback")}
        Asf.nav.setHistory(context.history);
        return <SubmittedFeedbacks sessionId={context.match.params.sessionId} />
    }
    renderDashboardPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("Dashboard");
        Asf.nav.setHistory(context.history);
        return <Dashboard />
    }
    renderRegistrationPage = (context: any, path: string) => {
        let str = path;
        let path1 = str.split("/");
        this.onSelect(path1);
        this.sideBarClick("New User Register");
        Asf.nav.setHistory(context.history);
        return <RegistrationForm />;
      }
    renderPageNotFound = () => {
        return <PageNotFound />;
    }
    PageRoute = (props: any, path: string) => {
        return <Route {...props} />;
    };


    toggleSidebar = () => {
        this.setState({
            isSideBarOpen: !this.state.isSideBarOpen
        }, () => {
            if (this.state.isSideBarOpen) {
                this.setState({
                    sidebarClassName: "openSideBar"
                })
            } else {
                this.setState({
                    sidebarClassName: "closeSidebar"
                })
            }
        })

    }
    render() {
        const res = Utils.localstorage("get", "res");
        let routes: any = HrRoutes;
        if (res && res.userType !== 'HR') {
            routes = EmpRoutes;
        }
        return (
            <div className='page-content'>
                {res ?
                    <div className="main-layout">
                        <Navbar className="wms-header-logo" toggleSidebar={this.toggleSidebar} />
                        <Sidebar altName="Logo" src={logo} routes={routes} className={this.state.sidebarClassName}>
                            <Switch>
                            <Route exact path="/registration-form" component={(context: any) => this.renderRegistrationPage(context, "/registration-form")}/>
                                <this.PageRoute exact path='/dashboard' component={(context: any) => this.renderDashboardPage(context, "/dashboard")} />

                                <this.PageRoute exact path='/feedback' component={(context: any) => this.renderInboxPage(context, '/feedback')} />

                                <this.PageRoute exact path='/active-feedback' component={(context: any) => this.renderActiveInboxPage(context, '/active-feedback')} />

                                <this.PageRoute exact path='/feedback/report/:sessionId?' component={(context: any) => this.renderFeedbackPage(context, '/feedback/report/:sessionId?')} />

                                <this.PageRoute exact path='/feedback/report/:sessionId/submitted-feedback/:sessionId?' component={(context: any,path: any) => this.renderSubmittedFeedback(context, path)} />

                                <this.PageRoute exact path='/active-feedback/provide-feedback-form/:sessionId' component={(context: any) => this.renderProvideFeedbackPage(context, '/active-feedback/provide-feedback-form')} />

                                <this.PageRoute exact path={res && res.userType !== 'HR'?
                                '/feedback/filled-feedback-form/:sessionId?/:userId?':'/feedback/report/:sessionId/filled-feedback-form/:sessionId?/:userId?' }component={(context: any) => this.renderFeedbackFormPage(context,"")} />

                                <this.PageRoute exact path='/my-profile' component={(context: any) => this.renderProfilePage(context, '/my-profile')} />

                                <this.PageRoute exact path='/feedback/create-session/:sessionId?' component={(context: any) => this.renderCreateSession(context, '/feedback/create-session/:sessionId?')} />

                                <this.PageRoute exact path='/ticket/update-ticket/:ticketId?/:ticketType?' component={(context: any) => this.renderUpdateTicket(context, '/ticket/update-ticket/:ticketId?/:ticketType?')} />

                                <this.PageRoute exact path='/ticket/create-ticket' component={(context: any) => this.renderCreateTicket(context, '/ticket/create-ticket')} />

                                <this.PageRoute exact path='/feedback/status/:sessionId' component={(context: any) => this.renderFeedbackStatus(context, '/feedback/status')} />

                                <this.PageRoute exact path='/ticket' component={(context: any) => this.renderWorkflowInboxPage(context, '/ticket')} />
                                
                                <Redirect from="/" to="/dashboard" />

                                {/* <Route path='*' exact={true} component={this.renderPageNotFound} /> */}

                            </Switch>
                        </Sidebar>
                    </div>
                    : <Redirect from="" to="/login" />
                }
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        onSelect: (path: string[]) => {
            const user = Utils.localstorage("get","res");
            dispatch(onPathSelect(path, user))
        },
        sideBarClick: (value:string) => dispatch(onSidebarCLick(value))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(AppRoutes);
