import _classCallCheck from "/home/devesh/project/Git_wms-app/lerna-monorepo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/classCallCheck";
export var SocialLoginCommon = function SocialLoginCommon() {
  _classCallCheck(this, SocialLoginCommon);
};

SocialLoginCommon.sdk = function (src, jssdk) {
  (function (d, s, id) {
    var js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = src;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', jssdk);
};