import _slicedToArray from "/home/devesh/project/Git_wms-app/lerna-monorepo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { Link, BrowserRouter } from '../../../../../node_modules/react-router-dom';
import './Sidebar.scss';

function SidebarItem(_ref) {
  var item = _ref.item;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      collapsed = _React$useState2[0],
      setCollapsed = _React$useState2[1];

  var label = item.label,
      path = item.path,
      icon = item.icon,
      onClickProp = item.onClick,
      children = item.children;

  function toggleCollapse() {
    setCollapsed(function (prevValue) {
      return !prevValue;
    });
  }

  function onClick(e) {
    if (Array.isArray(children)) {
      toggleCollapse();
    }

    if (onClickProp) {
      onClickProp(e, item);
    }
  }

  var expandIcon;

  if (Array.isArray(children) && children.length) {
    expandIcon = !collapsed ? React.createElement(MdExpandLess, {
      className: "sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"
    }) : React.createElement(MdExpandMore, {
      className: "sidebar-item-expand-arrow"
    });
  }

  return React.createElement(React.Fragment, null, React.createElement("li", {
    className: "sidebar-li",
    onClick: onClick
  }, React.createElement(BrowserRouter, null, React.createElement(Link, {
    to: path,
    className: "wms-ui-sidebar-a"
  }, React.createElement("span", {
    className: "sidebar-span"
  }, React.createElement("div", {
    className: "sidebar-icon-div"
  }, icon && icon), label), expandIcon))), React.createElement(Collapse, {
    in: !collapsed,
    timeout: "auto",
    unmountOnExit: true
  }, Array.isArray(children) ? React.createElement("ul", {
    className: "sidebar-ul"
  }, children.map(function (subItem, index) {
    return React.createElement(React.Fragment, {
      key: "".concat(subItem.name).concat(index)
    }, React.createElement(SidebarItem, {
      item: subItem
    }));
  })) : null));
}

export var Sidebar = function Sidebar(props) {
  var routes = props.routes,
      className = props.className,
      children = props.children;
  return React.createElement("div", {
    className: "wms-ui-maincontainer"
  }, React.createElement("div", {
    className: "wms-ui-sidebar-container ".concat(className)
  }, React.createElement("div", {
    className: "sidebar-inner-container"
  }, React.createElement("ul", {
    className: "sidebar-ul"
  }, routes.map(function (sidebarItem, index) {
    return React.createElement(React.Fragment, {
      key: "".concat(sidebarItem.name).concat(index)
    }, React.createElement(SidebarItem, {
      item: sidebarItem
    }));
  })))), React.createElement("div", {
    className: "wms-ui-container"
  }, children));
};