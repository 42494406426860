import { Message } from "../common";

var Utils =
/** @class */
function () {
  function Utils() {} // deskno validation //


  Utils.isValidDeskNo = function (deskNo) {
    var val = false;
    var splitString = deskNo.split(":");

    if (splitString.length != 2) {
      return false;
    }

    val = this.validDeskNo(splitString[0], splitString[1]);
    return val;
  };

  Utils.validDeskNo = function (hall, desk) {
    var object = new Object("HALL");

    if (hall.length > 4 && hall.substring(0, 4).toLowerCase() == "hall" && parseInt(hall.substring(4)) > 0 && desk.length > 4 && desk.substring(0, 4).toLowerCase() == "desk" && parseInt(desk.substring(4)) > 0) {
      return true;
    } else {
      return false;
    }
  }; // enter key check //


  Utils.enterCheck = function (str) {
    for (var i = 0; i < str.length; i++) {
      if (str[i] === '\n' || str[i] === '\r') {
        return true;
      }

      ;
    }

    ;
  }; // asset no validation


  Utils.isValidAssetId = function (assetId, department) {
    var departmentName;

    if (department == Message.Admin.ADMIN) {
      departmentName = Message.Admin.ADMIN;
    } else if (department == Message.Admin.SNA) {
      departmentName = Message.Admin.SNA;
    } else {
      departmentName = Message.Admin.HR;
    }

    var splitString = assetId.split("-");

    if (splitString.length != 4) {
      return false;
    }

    if (!(splitString[0] === "INNO")) {
      return false;
    }

    if (!(splitString[1] === departmentName)) {
      return false;
    }

    if (!(splitString[2].length > 1 && splitString[2].length < 7)) {
      return false;
    }

    if (parseInt(splitString[3]) <= 0) {
      return false;
    }

    return true;
  }; // Validates email //     


  Utils.validateEmail = function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }; // Validates name //


  Utils.validateName = function (name) {
    var re = /^[a-z ,.'-]+$/i;
    return re.test(String(name));
  }; // Not allow white spaces //


  Utils.validatehiteSpaces = function (name) {
    var re = /^\s+$/;
    return re.test(String(name));
  }; // Validates string cointain Alphanumeric //  


  Utils.validateAlphaNumeric = function (string) {
    var re = /\w/;
    return re.test(String(string));
  }; // Validate string only cointain alphabets //


  Utils.validateAlphabets = function (string) {
    var re = /^[A-Za-z]+$/;
    return re.test(String(string));
  }; // validate string is not more than 300 characters //    


  Utils.validateCharacterSize300 = function (string) {
    var length = string && string.length;

    if (length > 10 && length < 300) {
      return true;
    } else {
      return false;
    }
  }; // validate string length less than 10 //    


  Utils.validateMinLength10 = function (string) {
    var length = string && string.length;

    if (length > 10) {
      return true;
    } else {
      return false;
    }
  }; // Minimum eight characters, at least one letter, one number and one special character://


  Utils.validatePassword = function (password) {
    var re = /"^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"/;
    return re.test(String(password));
  }; // Validation for 2 digit number //


  Utils.validationTwodigit = function (num) {
    if (num < 10 && num > 0 && num.toString().length < 2) {
      return true;
    } else {
      return false;
    }
  }; // Validation for hall number //


  Utils.validationHallNo = function (num) {
    if (num < 4 && num > 0 && num.toString().length < 2) {
      return true;
    } else {
      return false;
    }
  }; // Validation for 3 digit number //


  Utils.validationThreedigit = function (num) {
    if (num < 100 && num > 0 && num.toString().length < 3) {
      return true;
    } else {
      return false;
    }
  }; // Validation for asset id //


  Utils.validationAssetId = function (num) {
    if (num < 1000 && num > 0 && num.toString().length < 4) {
      return true;
    } else {
      return false;
    }
  }; // only check its not null or undefined //


  Utils.requiredValidation = function (test) {
    var re = /\S/;

    if (test == "" || test == undefined) {
      return true;
    } else {
      return false;
    }
  }; // word limit of 50 world//


  Utils.wordLimit = function (test) {
    var re = /^.{5,100}$/;
    return re.test(String(test));
  }; // localstorage service


  Utils.localstorage = function (type, name, value) {
    if (type === "get") {
      var res = localStorage.getItem(name);

      if (res) {
        return JSON.parse(res);
      }
    } else if (type === "set") {
      var value1 = JSON.stringify(value);

      if (typeof value1 === "string") {
        localStorage.setItem(name, value1);
        return true;
      }
    }
  };

  return Utils;
}();

export { Utils };