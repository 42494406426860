import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

var StoreService =
/** @class */
function () {
  function StoreService() {
    var _this = this;

    this.initStore = function (reducer, initalState) {
      _this.store = createStore(reducer, initalState || {}, applyMiddleware(thunk));
      return _this.store;
    };
  }

  StoreService.prototype.getStore = function () {
    if (!this.store) {
      throw new Error("Store is not initialized yet!");
    }

    return this.store;
  };

  StoreService.prototype.dispatch = function (action) {
    return this.getStore().dispatch(action);
  };

  StoreService.prototype.getState = function () {
    return this.getStore().getState();
  };

  return StoreService;
}();

export { StoreService };