import React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "..";
import "./DialogBox.scss";
export var DialogBox = function DialogBox(props) {
  var action;

  if (props.showDefaultButton) {
    action = React.createElement(DialogActions, null, React.createElement(Button, {
      variant: props.defaultButtonVariant,
      className: "dialog-button",
      btnColor: props.defaultButton1Color,
      onClick: props.onClick2
    }, props.cancelButton ? props.cancelButton : "No"), React.createElement(Button, {
      variant: props.defaultButtonVariant,
      className: "dialog-button ".concat(props.buttonClass),
      btnColor: props.defaultButton2Color,
      onClick: props.onClick1
    }, props.confirmButton ? props.confirmButton : "Yes"), props.children);
  } else {
    action = React.createElement(DialogActions, null, props.children);
  }

  return React.createElement(Dialog, {
    id: props.id,
    className: "wms-ui-dialogBox ".concat(props.className),
    open: props.open,
    fullWidth: props.fullWidth,
    maxWidth: props.maxWidth
  }, React.createElement(DialogTitle, null, props.title), React.createElement(DialogContent, null, React.createElement(DialogContentText, null, props.content)), action);
};