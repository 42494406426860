import _objectWithoutProperties from "/home/devesh/project/Git_wms-app/lerna-monorepo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import { FormConsumer } from './Form';

var extractErrorObject = function extractErrorObject(error) {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return false;
  }
};

export var ErrorMessage = function ErrorMessage(_ref) {
  var children = _ref.children,
      name = _ref.name,
      rest = _objectWithoutProperties(_ref, ["children", "name"]);

  return React.createElement(FormConsumer, null, function (_ref2) {
    var errors = _ref2.errors;
    var errorMessage = extractErrorObject(errors[name]);
    return errorMessage ? React.createElement("span", Object.assign({}, rest), errorMessage) : null;
  });
};
ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired
};