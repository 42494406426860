import React from "react";
import { InputField, Button, Cards, Snackbar, ButtonLoader} from "@my-org/my-monorepo-ui-lib";
import {Asf, Utils} from "@my-org/inno-services";
import { Message } from "../../common";
import "./ForgotPassword.scss";
import IconButton from "@material-ui/core/IconButton";
import { FaTimes } from "react-icons/fa"
import { Common } from "../../common/common";
import { isThisISOWeek } from "date-fns/esm";

export interface ForgotPasswordProps {
    token?:any;
  }

export interface ForgotPasswordState {
  user: any;
  hasError?: string;
  isOpen?: boolean;
  errMessage?: string;
  loading?:boolean;
  passwordtype: string;
  showPassword: boolean;
  passwordSet: boolean;
}

export class ForgotPassword extends React.Component<ForgotPasswordProps,ForgotPasswordState> {
  loginForm = React.createRef<HTMLDivElement>();
  constructor(props:ForgotPasswordProps) {
    super(props);
    this.state = {
      loading:false,
      user: {
        email: "",
        password: ""
      },
      isOpen: false,
      errMessage: "",
      showPassword:false,
      passwordtype: "password",
      passwordSet: false,
    };
  }

  public componentDidMount(){
    if(this.loginForm.current ){
    window.onkeyup = (ev: KeyboardEvent): any => {
      if(ev.key === 'Enter'){
      if(this.props.token){
        this.save();
      }else{
        this.resetlink();
      }
      }
      }
    }
    }

  render() {
      console.log(this.props.token);
     return (
      <div className="main-login-container h-100 display-flex loginForm icon-left">
      <div className="login-container h-100 display-flex loginForm icon-right w-100" ref={this.loginForm}>
        <div className="login-container h-100 display-flex loginForm icon-right">
        <form className="loginForm" >
          <div className="card-container">
            <div className="card-login text-center">
              <div className="card-header font-weight-600 mt-4 mb-4">
                
                <img src={require("../../assets/images/Logo_login.png")}></img>
                {/* <span className="logo_title mt-4"> {Message.Login_heading} </span> */}
              </div>
              {
                  this.state.hasError &&
                  <div className="loginError errorTab p-4">{this.state.hasError}</div>
              }
              <div className="card-body mt-4">
                {/* <div className="login-input mt-4"><div className="w-8 mt-2">Email</div> */}
                {this.props.token?
                <span>
                <InputField
                    name={"newPassword"}
                    value={this.state.user.newPassword}
                    variant="outlined"
                    showPassword={this.state.showPassword}
                    handleClickShowPassword={this.handleClickShowPassword}
                    type={this.state.passwordtype}
                    placeholder="New password"
                    onChange={this.handleChange}
                    className="w-22 mt-4"
                />
                <InputField
                    name={"confirmPassword"}
                    value={this.state.user.confirmPassword}
                    variant="outlined"
                    showPassword={this.state.showPassword}
                    handleClickShowPassword={this.handleClickShowPassword}
                    type={this.state.passwordtype}
                    placeholder="Confirm password"
                    onChange={this.handleChange}
                    className="w-22 mt-4"
                />
                <div className="row">
                <Button className="login_btn button-color w-22 mt-4"
                    variant="contained"
                    onClick={this.state.loading? undefined : this.save}
                    >{this.state.loading?
                    <ButtonLoader type={Common.loadingType}>{Message.loading}</ButtonLoader>:Message.save}
                </Button>
                </div>
                </span>:
                <span>
                <InputField
                    name={"email"}
                    value={this.state.user.email}
                    variant="outlined"
                    placeholder="Enter your email"
                    onChange={this.handleChange}
                    className="w-22 mt-4"
                  />
                <div className="row">
                <Button className="login_btn button-color w-22 mt-4"
                    variant="contained"
                    onClick={this.state.loading? undefined : this.resetlink}
                    >{this.state.loading?
                    <ButtonLoader type={Common.loadingType}>{Message.loading}</ButtonLoader>:Message.send}
                </Button>
                </div>
                </span>}
                {/* <div className="mt-1 version">{Message.Version}</div> */}

                <div className="mt-1 login float-left" onClick={this.login}>{Message.Login_button}</div>
                 <div className="mt-1 version float-right">{Message.Version}</div>

              </div>
            </div>
          </div>
        </form>
        </div>
        {this.ErrMessage()}
      </div>
      </div>
    );
  }
  private login = () => {
    Asf.nav.navTo("/login");
  }
  private handleClickShowPassword = () => {
    this.setState({ showPassword:!this.state.showPassword});
  }
  private resetlink = () =>{
    this.setState({loading:true});
    const isValid = this.validate();
    if (isValid) {
      //Api call
      Asf.auth.forgot(this.state.user.email)
      .then((res:any) => {
        this.setState({errMessage: Message.password_reset_message,isOpen: true,loading:false});
      }).catch((err:any) => {
        if(err.status)
        {
          this.setState({errMessage:err.exMessage,isOpen: true,loading:false});
        }
        else{
          this.setState({errMessage:Message.Err_Message,isOpen: true,loading:false}); 
        }
      });
    }else{
      this.setState({loading:false});
    }
  }
  private save = () => {
    this.setState({loading:true});
    const isValid = this.validate();
    if (isValid) {
      //Api call
      let password = {"newPassword": this.state.user.newPassword}
      Asf.auth.reset(this.props.token,password)
      .then((res:any) => {
        this.setState({errMessage: Message.password_change_message,isOpen: true,loading:false,passwordSet:true});
      }).catch((err:any) => {
        if(err.status)
        {
          this.setState({errMessage:err.exMessage,isOpen: true,loading:false});
        }
        else{
          this.setState({errMessage:Message.Err_Message,isOpen: true,loading:false}); 
        }
      });
    }else{
      this.setState({loading:false});
    }
  }
  private handleChange = (value: string, name: string) => {
    let user = this.state.user;
    user = {
      ...user,
      [name]: value,
    }
    this.setState({ user: user });
  };

  private validate = () => {
    if(!this.props.token){
    if (!Utils.validateEmail(this.state.user.email)) {
      this.setState({ hasError: Message.Email_validation_error });
      return false;
    }
    }
    else{
        if(!this.state.user.newPassword){
        this.setState({ hasError: Message.Password_validation_error });
        return false;
        }
        else if (!this.state.user.confirmPassword) {
            this.setState({ hasError: Message.Confirm_password_validation });
            return false;
        }
        else if ((this.state.user.newPassword) !== (this.state.user.confirmPassword)) {
            this.setState({ hasError: Message.Confirm_Password_validation_error });
            return false;
        }
    }
    this.setState({ hasError: undefined });
    return true;
  };
  private ErrMessage = () =>{
    return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
              action={<IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>}
             open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage}/>
  } 
 
  private handleClose = () =>{
    if(this.state.passwordSet === true){
      this.login();
    }else{
      this.setState({isOpen:false,errMessage:""});
    }
  } 
}