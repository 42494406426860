import { ApiService } from "./ApiService";
import { AuthService } from "./AuthService";
import { LoggerService } from "./LoggerService";
import { NavigationService } from "./NavigationService";
import { StoreService } from "./StoreService";

var Builder =
/** @class */
function () {
  function Builder() {}

  Builder.prototype.build = function (creator) {
    if (!this.instance) {
      this.instance = creator();
    }

    return this.instance;
  };

  return Builder;
}();

var AppServiceFactory =
/** @class */
function () {
  function AppServiceFactory() {
    this._logger = new Builder();
    this._nav = new Builder();
    this._api = new Builder();
    this._store = new Builder();
    this._auth = new Builder();
  }

  Object.defineProperty(AppServiceFactory.prototype, "logger", {
    get: function get() {
      return this._logger.build(function () {
        return new LoggerService();
      });
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(AppServiceFactory.prototype, "nav", {
    get: function get() {
      return this._nav.build(function () {
        return new NavigationService();
      });
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(AppServiceFactory.prototype, "api", {
    get: function get() {
      return this._api.build(function () {
        return new ApiService();
      });
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(AppServiceFactory.prototype, "store", {
    get: function get() {
      return this._store.build(function () {
        return new StoreService();
      });
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(AppServiceFactory.prototype, "auth", {
    get: function get() {
      return this._auth.build(function () {
        return new AuthService();
      });
    },
    enumerable: true,
    configurable: true
  });
  return AppServiceFactory;
}();

export { AppServiceFactory };
export var Asf = new AppServiceFactory();