import React from "react";
export var Image = function Image(props) {
  var className = props.className,
      src = props.src,
      altName = props.altName;
  return React.createElement("img", {
    className: className,
    src: src,
    alt: altName
  });
};