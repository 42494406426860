import React from 'react';
import { Label} from '@my-org/my-monorepo-ui-lib';
import './feedback-header.scss';
import {Asf} from "@my-org/inno-services";

interface IFeedbackHeadProps{
    sessionId: any;
}

export interface IFeedbackHeadState{
      data:any;
      presenter:string;
}
export class FeedbackHeader extends React.Component<IFeedbackHeadProps, IFeedbackHeadState>{
    public state = {
        data: {
            audience: {
                id:"",
                firstName:""
            },
            presenters: [{
                id:"",
                firstName:"",
                lastName:"",
                userDesignation: ""

            }],
            session: {
                id:"",
                title: "",
                sessionDate: "",
                duration:"",
                sessionTime:"",
            }
        },
        presenter:""
    }

    public componentDidMount(){
        Asf.api.get(`/session/${this.props.sessionId}`)
        .then((res: object[]) => {
            if(res){
             this.setState({data : res})
            }
        }).catch((err:any) => {
            Asf.logger.log(err);
        })
    }

    render(){
        let data = this.state.data;
     
        return(
            <div className="Feedback-header display-flex">
            <div className="session-head-detail">  
                <div className="card-detail-label"><Label content={`Session`}/></div>
                <div className="card-detail-data">{data.session.id}</div>
            </div>
            <div className="session-head-detail">
                <div className="card-detail-label"><Label content={`Topic`}/></div>
                <div className="card-detail-data">{data.session.title}</div>                 
                </div>
            <div className="session-head-detail">
                <div className="card-detail-label"><Label content={`Presenters`}/></div>
                <div className="card-detail-data">{`${data.presenters[0].firstName} ${data.presenters[0].lastName}`}</div>
            </div>
            <div className="session-head-detail">  
                <div className="card-detail-label"><Label content={`Designation`}/></div>
                <div className="card-detail-data">{data.presenters[0].userDesignation}</div>
                 </div>
            <div className="session-head-detail">
                <div className="card-detail-label"><Label content={`Date`}/></div>
                <div className="card-detail-data">{data.session.sessionDate}</div>
            </div>
            <div className="session-head-detail">
                <div className="card-detail-label"><Label content={`Time`}/></div>
                <div className="card-detail-data">{data.session.sessionTime}</div>
            </div>
            <div className="session-head-detail">
                <div className="card-detail-label"><Label content={`Duration`}/></div>
                <div className="card-detail-data">{data.session.duration}</div>
            </div>
            </div>
            //    <div className="Feedback-header display-flex">
            //        <div><Label className="font-weight-bold" content="Session: "/>{data.session.id}</div> 
            //        <div><Label className="font-weight-bold" content="Presented by: "/>{`${data.presenters[0].firstName} ${data.presenters[0].lastName}`}</div>
            //        <div><Label className="font-weight-bold" content="Designation: "/>{data.presenters[0].userDesignation}</div>
            //        <div><Label className="font-weight-bold" content="Topic: "/>{data.session.title}</div>                 
            //        <div><Label className="font-weight-bold" content="Date: "/>{data.session.sessionDate}</div>
            //    </div>           
        );
    }
}