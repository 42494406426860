import React from "react";
import {FbRow} from './FbRow';
import {FeedbackQuestions, FeedbackOptions, OverallFeedbackOptions} from '../../Sample-json/FeedbackData';
import './FilledFeedbackForm.scss';
import { Message } from "../../common";
import { FaCheck } from 'react-icons/fa';
import {Button, Spinner} from '@my-org/my-monorepo-ui-lib';
import { Asf } from "@my-org/inno-services";

export interface IfilledFromProps {
    sessionId: string;
    userId: number;
}


export class FilledFeedbackForm extends React.Component<IfilledFromProps, {}> {

    state = {
        loading: true,
        data: { empName: "", sessionName: "",title:"", presenters: "", date: "", designation: ""},
        quesAns: [],
        otherQuesAns: [],
    }

    public componentDidMount(){
        let quesAnswer:object[] =[];
        let otherQues:object[] =[];
        Asf.api.get(`/session/${this.props.sessionId}/feedbacks`)
        .then((res: any) => {
           let rowdata;
              res.map((result: any) => {
                rowdata = { empName: "", sessionName: "",title:"", presenters: "", date: "", designation: ""}
                         
                         if(this.props.userId == result.feedbackBy.id){
                                rowdata.empName = `${result.feedbackBy.firstName} ${result.feedbackBy.lastName}` ;
                                rowdata.sessionName = result.session.id;
                                rowdata.title = result.session.title;
                                rowdata.date = `${result.session.sessionDate} ${result.session.sessionTime}`;
                                rowdata.designation = result.feedbackBy.userDesignation;
                                let presentersLength = result.presenters && result.presenters.length;
                                for(let i=0;i<presentersLength;i++) {
                                    const presentedByData = result.presenters[i] || result.presenters[i];
                                    rowdata.presenters+=` ${presentedByData.firstName}`;
                                }

                                for( let i = 0; i < 13; i++){
                                  if(i < 10 ){
                                quesAnswer.push(result.quesAns[i]);
                                  } else{
                                      otherQues.push(result.quesAns[i]);
                                  }
                                }
                                this.setState({data: rowdata, quesAns: quesAnswer, otherQuesAns:otherQues}, ()=>{
                                    this.setState({ loading:false });
                                });
                         }
                      });
                      
        }).catch((err: any) => {
        
        });
    }

    public render() {
        const data= this.state.data;
        const quesAns = this.state.quesAns;
        const otherQuesAns:any = this.state.otherQuesAns;
        if (this.state.loading) {
            return <Spinner className="ml-15"/> ;
        }else{
        return (
            <React.Fragment>
    <Button className="primary button-color w-f-btn w-15 mt-2 ml-10 mb-2" variant="contained" btnColor="primary" onClick={this.printForm}>{Message.Print}</Button> 
             <div>

         <div className="filled-feedback-form-container">    
            <div className="table-container" id="feedbackForm-table">
            <style>{"\
                @media print {\
                    .table-container{\
                        margin-left: auto;\
                        margin-right: auto;\
                        width: 21cm;\
                        height: 29.7cm;\
                        font-size: 1.2rem;\
                    }\
                    .table-credential{\
                        width: inherit;\
                    }\
                    table{\
                        width: inherit;\
                        border-spacing: 0px;\
                        text-align: center;\
                        border-top: 1px solid black;\
                        border-left: 1px solid black;\
                    }\
                    td, th{\
                        padding: 5px;\
                        border-bottom: 1px solid black;\
                        border-right: 1px solid black;\
                    }\
                    .th{\
                        width: 2cm;\
                    }\
                    .td{\
                        width: 5cm;\
                    }\
                    .w-heading{\
                        text-align: center;\
                        marging: 10px;\
                    }\
                    .w-td-ques {\
                        text-align: left;\
                        padding-left: 5px;\
                    }\
                    .w-para {\
                        margin-bottom: 0px;\
                    }\
                 }\
                \
              "}</style>
                <h2 className="w-heading">{Message.Form_heading}</h2>
                <div className="table-credential">
                    <table>
                        <tbody>
                            <tr>
                                <td className="td pl-2">{Message.Name}</td>
                                      <td className="data">{data.empName}</td>
                            </tr>
                            <tr>
                                <td className="td pl-2">{Message.Designation}</td>
                                <td className="data">{data.designation}</td>
                            </tr>
                            <tr>
                                <td  className="td pl-2">{Message.Training_Topic}</td>
                                <td className="data">{data.title}</td>
                            </tr>
                            <tr>
                                <td  className="td pl-2">{Message.Trainer_Name}</td>
                                <td className="data">{data.presenters}</td>
                            </tr>
                            <tr>
                                 <td  className="td pl-2">{Message.Training_Date}</td>
                                <td className="data">{data.date}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="w-para font-weight-700 mb-2">{Message.Heading}</p>
                <table>
                        <tbody>
                            <tr>
                                <th>{Message.SNo}</th>
                                    <th>{Message.question}</th>
                                {/* tslint:disable-next-line: jsx-no-multiline-js*/}
                                {FeedbackOptions.map((fbOption) =>
                                    // tslint:disable-next-line: jsx-key
                                    <th className='th'>{fbOption.option}</th>,
                                )}
                            </tr>
                            {/* tslint:disable-next-line: jsx-no-multiline-js*/}
                            {quesAns.map((fbQues ,index) =>
                                // tslint:disable-next-line: jsx-key
                                <FbRow fbQues={fbQues} index={index}/>,
                            )}
                        </tbody>
                </table>
                            <p className="w-para font-weight-700 mb-2">{Message.Q11}</p>
                <table>
                    <tbody>
                        <tr>
                            {/* tslint:disable-next-line: jsx-no-multiline-js*/}
                            {OverallFeedbackOptions.map((fbOption) =>
                                // tslint:disable-next-line: jsx-key
                                
                            <th className='th'>{fbOption.option}</th>,
                            )}
                        </tr>
                        <tr>
                            {/* tslint:disable-next-line: jsx-no-multiline-js*/}
                            {OverallFeedbackOptions.map((fbOption) =>
                                // tslint:disable-next-line: jsx-key
                                <th>{otherQuesAns[0] && (fbOption.option === otherQuesAns[0].ans) ? <FaCheck /> : null}</th>,
                            )}
                        </tr>
                    </tbody>
                </table>
                <p className="w-para font-weight-700">{Message.Q12}</p>
                            <p className="w-para pl-4">{otherQuesAns[1].ans ? otherQuesAns[1].ans : "--"}</p>
                <p className="w-para font-weight-700 ">{Message.Q13}</p>
                <p className="w-para pl-4">{otherQuesAns[2].ans ? otherQuesAns[2].ans : "--"}</p>
            </div>
            </div>
            </div>
            </React.Fragment>
        );
    }
}
    private printForm = () => {
        let divToPrint=document.getElementById("feedbackForm-table");
        let newWin= window.open("");
        if(newWin && divToPrint) {
            newWin.document.write(divToPrint.outerHTML);
            newWin.document.title = this.props.sessionId;
            newWin.print();
            newWin.close();
        }
    } 
}