import React from 'react';
import { Label, Cards, Spinner} from '@my-org/my-monorepo-ui-lib';
import './Inbox.scss';
import { SessionHead } from '../../components';
import {Asf} from "@my-org/inno-services";
import {Common} from '../../common/common';
import { Message } from '../../common';
import { connect } from 'react-redux';
import { sessionCount } from '../../redux/action';

interface ActiveInboxProps{
    setCount?: (val: boolean) => any
}
interface ActiveInboxState{
    row: any;
    loading: boolean;
}
class ActiveInbox extends React.Component<ActiveInboxProps, ActiveInboxState> {

    constructor(props: any){
        super(props);
        this.state={
        loading: true,
        row: [],
      }   
    }

  async componentDidMount(){

    const res = "active"; 
    const response = await Common.getSession(res);
    console.log(response);
    this.setState({row: response},()=>{
        this.setState({ loading: false});
    });
    if(this.state.row.length === 1){
     this.props.setCount && this.props.setCount(true);
     
    }
    }   
    public render() {
        if (this.state.loading) {
            return <Spinner className="ml-15"/> ;
        }else{
        return (
                <div className="active-inbox-container display-flex">
                    { this.state.row.length !== 0 ?
                    (this.state.row.map((detail: any)=>
                    <div className="active-inbox-container-emp display-flex">
                        <Cards className="session-head-card m-6 min-h15 h-15 w-77"><SessionHead detail={detail}/></Cards>
                    </div>
                    )): <p className="no-session-card text-center h-18 pt-10 p-10 display-flex">{Message.No_session}</p>}
                </div>
        );
    } 
}
}
const mapDispatchToProps = (dispatch: any) => {
    return {
      setCount: (value: boolean) => dispatch(sessionCount(value))
    }
  }
  export default connect(
    null,
    mapDispatchToProps
  )(ActiveInbox);
