import { HttpService } from "./HttpService";

var ApiService =
/** @class */
function () {
  function ApiService() {
    this._httpApi = new HttpService("http://54.200.246.135/wms/v1.0/api"); // this._httpApi = new HttpService("http://54.200.246.135/wms/test/v1.0/api");
    // this._httpApi = new HttpService("http://192.168.0.33:5055/v1.0/api");
  }

  ApiService.prototype.getUsers = function () {
    return this._httpApi.get("/users");
  };

  ApiService.prototype.update = function (path, body, params) {
    return this._httpApi.put(path, body, params);
  };

  ApiService.prototype.getProfile = function (path, params) {
    return this._httpApi.get(path, params);
  };

  ApiService.prototype.post = function (path, body, params) {
    return this._httpApi.post(path, body, params);
  };

  ApiService.prototype.patch = function (path, body, params) {
    return this._httpApi.patch(path, body, params);
  };

  ApiService.prototype.get = function (path, params) {
    return this._httpApi.get(path, params);
  };

  ApiService.prototype.delete = function (path) {
    return this._httpApi.delete(path);
  };

  return ApiService;
}();

export { ApiService };