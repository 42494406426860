import { TabsComponent, TabComponent, Button, Cards, DataGrid, DropDown, InputField, Label, ModalComponent, ReactSelect, Spinner,  TypographyComponent, Snackbar, Span, ButtonLoader, Form, FormInput, DialogBox, CommentTable, CommentTableRow, MToolTip} from "@my-org/my-monorepo-ui-lib";
import React, { Component } from "react";
import {Ticket} from "../../models/Tickets";
import {Asf, Utils} from "@my-org/inno-services";
import "./CreateTicket.scss";
import { Message } from "../../common";
import IconButton from '@material-ui/core/IconButton';
import { FaTimes } from 'react-icons/fa';
import { Common } from "../../common/common";
import { TicketHeader } from "./TicketHeader";
import { MdChatBubble, MdEdit } from "react-icons/md";

export interface IUpdateTicketProps {

    submit?: (val?: any) => any;
    ticketId?: string;
    ticket?: Ticket;
    hasError?: any;
    ticketType:any;
}
export interface IUpdateTicketState {
    disable:boolean;
    btnloader:boolean;
    ticket?: Ticket;
    ticketdetail?:Ticket;
    priority: any;
    department: any;
    isEdit: boolean;
    team: any;
    visible: boolean;
    res: any;
    isApproved?: boolean;
    hasError?: string;
    comment?: boolean;
    comments?: any;
    loading?: boolean;
    isOpen: boolean;
    errMessage: string;
    isSelected: "ALL"|"WORKLOG"|"COMMENT";
    commentData: string;
    Message?: string;
    showButton?: boolean;
    clickOption?:string;
    open:boolean;
    clickedoption: string;
    changeStatus?: boolean;
    commentLoad?: boolean;
    error?: any;
}
export class UpdateTicket extends Component<IUpdateTicketProps, IUpdateTicketState> {

    constructor(props: IUpdateTicketProps) {
        super(props);

        this.state = {
            error: {},
            clickOption:"",
            btnloader:false,
            loading: true,
            disable:false,
            commentLoad: false,
            ticketdetail: {},
            ticket: props.ticket,
            commentData:"",
            priority: [
                {value: "High", label: "High"},
                {value: "Medium", label: "Medium"},
                {value: "Low", label: "Low"},
            ],
            department: [
                {value: "HR", label: "HR"},
                {value: "ADMIN", label: "ADMIN"},
                {value: "SNA", label: "SNA"},
            ],
            comment: false,
            comments: {
                columns: [
                    {
                        headerName: "Date",
                        field: "created",
                        sort: "dec",
                        sortable: true,
                        filter: true,
                        lockPosition: true,

                    },
                    {
                        headerName: "Comment",
                        field: "comment",
                        width: 500,
                        tooltip: (params: any) =>  params.data.comment,
                        lockPosition: true,
                    },
                ], rows: [],
            },
            isEdit: false,
            changeStatus: false,
            team: [],
            visible: false,
            res: {},
            isApproved: false,
            isSelected: "ALL",
            errMessage: "",
            isOpen: false,
            open:false,
            Message:"",
            showButton: false,
            clickedoption:"",
        };
    }

    public componentWillReceiveProps(newProps: any) {
        this.setState({ ticket: newProps.ticket, hasError: newProps.hasError });
    }

    public componentDidMount() {
        let res = Utils.localstorage("get","res");
        this.setState({ res: res });
        const props = this.props;
        const state = this.state;
        if (props.ticketId) {
            Asf.api.get(`/tickets/${props.ticketId}`)
                .then(async (x: any) => {
                    const ticket = x;
                    this.setState({ ticket: ticket });
                    const api = [];
                    api.push( Asf.api.get(`/users/${props.ticketId}/userlist`));
                    api.push(Asf.api.get(`/comments/get/${props.ticketId}?commentType=ALL`));
                    const _res = await Promise.all(api);
                    if (_res[1]) {
                        let _comments = _res[1].Comments;
                        let comments = this.state.comments;
                        let rows = comments.rows.concat(_comments);
                        if (rows[0] === null) {
                            comments.rows = [];
                        } else {
                            comments.rows = rows;
                        }
                        this.setState({comments: comments});
                    }
                    if (_res[0]) {
                        let _team: [] = _res[0].Users;
                        console.log(_team);
                        const transformed = _team.map(({ firstName, id,lastName }) => ({ value: id, label: `${firstName} ${lastName}`}));
                        this.setState({team: transformed},()=>{this.setState({ loading: false });});
                    }
                }).catch((err: any) => {
                    if(err.status)
                    {
                        this.setState({errMessage:err.exMessage,isOpen: true,loading:false});
                    }
                    else{
                        this.setState({errMessage:Message.Err_Message,isOpen: true,loading:false}); 
                    }
                });
        } else {
            this.setState({ loading: false });
        }

    }

    public render() {
            const options = [
                {   id: 1,
                    value: (this.props.ticketType === "AssignedToMe") && ((this.state.res.  userType && this.state.res.userType.includes('MASTER'))) ?"Assign To":undefined,
                    onclick: this.isApproved,
                },
                {
                    id: 2,
                    value: (this.props.ticketType === "AssignedToMe") && (this.state.ticket && this.state.ticket.resolution == "New") && (this.state.ticket.assigned) ?"Approve":undefined,
                    onclick: ()=>this.setState({ changeStatus: true}),
                },
                {
                    id: 3,
                    value: (this.props.ticketType === "AssignedToMe") && (this.state.res.userType && this.state.res.userType.includes('MASTER')) || (this.state.res.userType =="HR") ? (this.props.ticketType !== "CreatedByMe" && this.state.ticket && this.state.ticket.resolution !== "Closed")?"Decline":undefined:undefined,
                    onclick: ()=>this.handleClose("handleDecline")
                },
                {
                    id: 4,
                    value: this.state.ticket && this.state.ticket.resolution == "Closed" ? undefined : "Close",
                    onclick: ()=>this.handleClose("handleClose")
                },
                ,
                {
                    id: 5,
                    value: (this.props.ticketType === "CreatedByMe") && (this.state.ticket && this.state.ticket.status === Message.Closed)?"Re-Open":undefined,
                    onclick: this.reOpen,
                },
            ];
    
        if (this.state.loading) {
            return <Spinner className="ml-20"/> ;
        }
        else{
        return (
            <div >
                {console.log("check",this.state.ticket && this.state.ticket.resolution == "NEW")}
                <ModalComponent
                    open={this.state.visible}
                    handleClose={this.closeModal}
                >
                {this.state.clickedoption === "handleComment"? <h4>ADD COMMENTS</h4>:this.state.clickedoption === "handleClose"?<div className="close-alert mb-4">Do you want to close ?</div>:this.state.clickedoption === "handleDecline"?<div className="close-alert mb-4">Do you want to decline ?</div>:""}
                    
                    <InputField
                        className="commentBox"
                        helperText={this.state.error.commentError}
                        name={"comment"}
                        multiline={true}
                        variant="outlined"
                        value={this.state.ticketdetail && this.state.ticketdetail.comment}
                        placeholder={this.state.clickedoption === "handleComment"?"Comments":"Add Comments"}
                        onChange={this.updateFormFieldValue}
                    />
                    <Button
                        onClick={this.addComment}
                        className="btn float-right mt-2"
                        variant="contained"
                    >{this.state.btnloader?
                        <ButtonLoader type={Common.loadingType} className="">{Message.loading}</ButtonLoader>
                        :this.state.clickOption === Message.Closed?"OK":"COMMENT"}
                    </Button>
                    <Button
                        onClick={this.closeModal}
                        className="float-right mt-2 mr-2 "
                        variant="contained"
                    >{Message.Cancel_Button}
                    </Button>
                </ModalComponent>
                <div>
                <TicketHeader ticket={this.state.ticket} />
                <Cards className="create-ticket-card-detail pr-2 pl-2">
                <div className="display-team">
                    <div className="team-header-label">
                        <Label className="Ticket-header-detail-label"content="Ticket Id "/>
                    </div>:
                        <span className="Ticket-header-detail-data ml-5">{this.props.ticketId && this.props.ticketId}
                        </span>
                    </div>
                    <div className="display-team mt-4">
                    <div className="team-header-label">
                        <Label className="Ticket-header-detail-label"content="Department "/>
                    </div>:
                        <span className="Ticket-header-detail-data ml-5">{this.state.ticket && this.state.ticket.department}
                        </span>
                    </div>
                    <div className="mt-4 display-team">
                        <div className="team-header-label">
                        <Label className="Ticket-header-detail-label"content="Team "/>
                        </div>:
                        <div className="ml-5">
                        {this.state.team.map((team: any, index:number) =>
                            <span className="Ticket-header-detail-data">
                            {index !== 0 ? ", ":" "}{team.label}</span>
                        )}
                        </div>
                    </div>
                </Cards>
                {/* {((this.state.res.userType && this.state.res.userType !== Message.Admin.SUPER_ADMIN)) || ((this.state.res.userType && this.state.res.userType === Message.Admin.SUPER_ADMIN) && (this.props.ticketType === "CreatedByMe" || this.props.ticketType === "Open")) ?
                <TabsComponent 
                value={this.state.clickedoption}
                orientation= 'horizontal'
                variant="standard"
                className="create-ticket-card-detail tab-width">
                    <TabComponent
                    label={"Comment"}
                    onClick={()=>this.handleComment("handleComment")}
                    value={"handleComment"}
                    >
                    </TabComponent>
                    {(this.props.ticketType === "AssignedToMe") && ((this.state.res.userType && this.state.res.userType.includes('MASTER'))) ?
                        <TabComponent
                            label={"Assign To"}
                            value={"isApprove"}
                            onClick={this.isApproved}
                        />:undefined}
                    
                     {this.props.ticketType === "CreatedByMe" && this.state.ticket && this.state.ticket.resolution == "New"?
                        <TabComponent
                            label={"Edit"}
                            value={"handleEdit"}
                            onClick={this.handleEdit}
                        />:undefined}
                    {(this.props.ticketType === "AssignedToMe") && (this.state.ticket && this.state.ticket.resolution == "New") && (this.state.ticket.assigned) ?
                        <TabComponent
                            label={"Approve"}
                            value={"handleEdit"}
                            onClick={()=>this.setState({ changeStatus: true})}
                        />:undefined}
                    {(this.props.ticketType === "AssignedToMe") && (this.state.res.userType && this.state.res.userType.includes('MASTER')) || (this.state.res.userType =="HR") ? this.props.ticketType !== "CreatedByMe"?
                        <TabComponent
                            label={"Decline"}
                            value={"handleDecline"}
                            onClick={()=>this.handleClose("handleDecline")}
                        />:undefined:undefined}
                        <TabComponent
                            label={"Close"}
                            value={"handleClose"}
                            onClick={()=>this.handleClose("handleClose")}
                        />
                    {(this.props.ticketType === "CreatedByMe") && (this.state.ticket && this.state.ticket.status === Message.Closed)?
                        <TabComponent
                            label={"Re-Open"}
                            value={"reOpen"}
                            onClick={this.reOpen}
                        />:undefined}
                </TabsComponent>
                :undefined} */}

                <Cards className="create-ticket-card-detail update-ticket-card">
                {((this.state.res.userType && this.state.res.userType !== Message.Admin.SUPER_ADMIN)) || ((this.state.res.userType && this.state.res.userType === Message.Admin.SUPER_ADMIN) && (this.props.ticketType === "CreatedByMe" || this.props.ticketType === "Open")) ?
                    <div>
                    <div className="display-flex option-menu">
                    <MToolTip title="Edit" placement={"left"}>
                    <span>
                    <MdEdit
                    className={this.props.ticketType === "CreatedByMe" && this.state.ticket && this.state.ticket.resolution == "New"? "active-option cursor-pointer mr-2" : "disable-option mr-2"}
                    size="1.5em" 
                    onClick={this.props.ticketType === "CreatedByMe" && this.state.ticket && this.state.ticket.resolution == "New"? this.handleEdit : undefined}
                    />
                    </span>
                    </MToolTip>
                    <MToolTip title="Comment" placement={"left"}>
                    <span>
                    <MdChatBubble
                    className={this.state.ticket && this.state.ticket.resolution == "Closed"?"disable-option mr-1":"active-option cursor-pointer mr-1"}
                    size="1.5em"
                    onClick={this.state.ticket && this.state.ticket.resolution == "Closed"? undefined: ()=>this.handleComment("handleComment")}
                    />
                    </span>
                    </MToolTip>
                    <DropDown className={this.state.ticket && this.state.ticket.resolution == "Closed" && this.props.ticketType !== "CreatedByMe"? "disable-option w-1":"active-option cursor-pointer w-1"} listClassName="menu-list" type="icon" 
                    value={(this.props.ticketType === "AssignedToMe") && (this.state.ticket && this.state.ticket.resolution == "Closed")?undefined : options} label="dropdown"/>
                    </div>
                    <hr />
                    </div>:
                undefined}
                    {
                        this.state.hasError &&
                        <div className="error-msg error-tab">{this.state.hasError}
                        </div>
                    }
                    
                    <div className="title-div option-div pl-2 pr-2">
                    </div>
                    {/* new form design */}
                    <div className="pl-2 pr-2">
                    <div className="title-div form-row">
                    <div className="form-label label float-left team-header-label">
                    {`Title`}<span className="requiredfield">{this.state.isEdit? " *":""}</span>
                    </div>
                    {/* <Label content="Title:"/> */}
                    <span className="row-data inputfield detail-data">:
                    <span className="ml-5">
                        {(this.props.ticketId && !this.state.isEdit) ?
                            this.state.ticket && this.state.ticket.title:
                            <InputField name={"title"}
                                helperText={this.state.error.titleError}
                                className="title w-44"
                                variant="outlined"
                                defaultValue = {this.state.ticket && this.state.ticket.title}
                                value={this.state.ticketdetail && this.state.ticketdetail.title}
                                placeholder="Title.."
                                onChange={this.updateFormFieldValue}
                                required={true} />  
                        }
                    </span>
                    </span>
                    </div>

                    <div className="in-line form-row">
                        <div className="team-header-label"><span className="form-label label float-left">{`Priority`}<span className="requiredfield">{this.state.isEdit? " *":""}</span></span>
                        </div>
                    <span className="row-data inputfield detail-data">:
                    <span className="ml-5">
                    {(this.props.ticketId && !this.state.isEdit) ?
                    this.state.ticket && this.state.ticket.priority :
                            <ReactSelect 
                            name='priority' isMulti={false}
                            className="chip-width w-44"
                            placeholder="Priority"
                            defaultValue={[
                                {value: this.state.ticket && this.state.ticket.priority, label: this.state.ticket && this.state.ticket.priority},]}
                            onChange={this.updateReactSelect}
                            options={this.state.priority}/>
                    }
                    </span>
                    </span>        
                    </div>

                    {(this.props.ticketId && !this.state.changeStatus) ?
                    undefined :
                    <div className="title-div form-row">
                    <div className="team-header-label">
                    <span className="form-label label float-left">{`Resolution`}<span className="requiredfield">{this.state.changeStatus? " *":""}</span></span>
                    </div>
                    {/* <Label content="Title:"/> */}
                    <span className="row-data inputfield detail-data assignto">:
                    <span className="ml-5">
                            <ReactSelect
                            helperText={this.state.error.resolutionError}
                            name='resolution' isMulti={false}
                            className="chip-width w-44"
                            placeholder="Resolution"
                            onChange={this.updateReactSelect}
                            required={true}
                            options={[{value: "IN_PROGRESS", label: "In-Progress"}]}/>
                    </span>
                    </span>
                    </div>
                    }

                    <div className="in-line form-row">
                    <div className="team-header-label"><span className="form-label label float-left">{`Description`}<span className="requiredfield">{this.state.isEdit? " *":""}</span></span>
                    </div>
                    <span className="row-data inputfield detail-data">:
                    <span className="ml-5">
                        {(this.props.ticketId && !this.state.isEdit) ?
                        this.state.ticket && this.state.ticket.description :
                        <InputField name={'description'}  multiline={true} rowsMax={2}
                        helperText={this.state.error.descriptionError}
                        variant="outlined" value={this.state.ticketdetail && this.state.ticketdetail.description} placeholder="Description.." defaultValue = {this.state.ticket && this.state.ticket.description}
                        required={true} className="input-description w-44"onChange={this.updateFormFieldValue} />
                        }
                    </span>
                    </span>        
                    </div>
                    <div className="title-div form-row">
                    <div className="team-header-label">
                    <span className="form-label label float-left">{`Assigned`}<span className="requiredfield">{this.state.isApproved? " *":""}</span></span>
                    </div>
                    {/* <Label content="Title:"/> */}
                    <span className="row-data inputfield detail-data assignto">:
                            <span className="ml-5 w-44">
                            {this.props.ticketId && this.state.isApproved?
                                <ReactSelect
                                helperText={this.state.error.assignedToError}
                                name='assignedTo'isMulti={false} 
                                defaultValue={[{value: this.state.ticket && this.state.ticket.assigned, label: this.state.ticket && this.state.ticket.assigned},]} 
                                onChange={this.updateReactSelect} options={this.state.team}/>
                                :this.state.ticket && this.state.ticket.assigned?this.state.ticket.assigned:"NA"
                            }
                            </span>
                    </span>
                    </div>
                    {((this.state.isEdit) || (this.state.isApproved) || (this.state.changeStatus))?
                    <div className="mt-10 h-5">
                        <Button
                                name={"Update"}
                                onClick={this.state.btnloader?undefined:this.update}
                                className="wms-button button-color float-right"
                                variant="contained"
                                btnColor="primary"
                            >{this.state.btnloader?
                                <ButtonLoader type={Common.loadingType} className="">{Message.loading}</ButtonLoader>:Message.Update_button}
                        </Button>
                        <Button
                            name="Cancel"
                            onClick={this.cancle}
                            className="wms-button ticket-cancel-btn float-right mr-5"
                            variant="contained"
                        >{Message.Cancel_Button}
                        </Button>
                    </div>:undefined}
                    </div>
                    </Cards>
                    <TabsComponent 
                    value={this.state.isSelected}
                    orientation= 'horizontal'
                    variant="standard"
                    className="create-ticket-card-detail tab-width PrivateTabIndicator-colorPrimary-143">
                    <TabComponent
                    className={this.state.isSelected === "ALL"?"selected-tab":""}
                    label={"ALL"}
                    onClick={() => this.commentFilter("ALL")}
                    value={"ALL"}
                    />
                    <TabComponent
                    className={this.state.isSelected === "COMMENT"?"selected-tab":""}
                    label={"USER COMMENT"}
                    onClick={() => this.commentFilter("COMMENT")}
                    value={"COMMENT"}
                    />
                    <TabComponent
                    className={this.state.isSelected === "WORKLOG"?"selected-tab":""}
                    label={"SYSTEM COMMENT"}
                    onClick={() => this.commentFilter("WORKLOG")}
                    value={"WORKLOG"}
                    />
                </TabsComponent>
                    <Cards className="create-ticket-card-detail scroll-commentBox h-30">
                    {/* new form design */}
                    {this.state.commentLoad ? <Spinner className="comment-loader mt-20"/> :
                            <CommentTable>
                                {this.state.comments.rows.map((comment:any) =>{
                                return <div className="comment-table">
                                        <CommentTableRow
                                        className="mt-2"
                                        name={comment.commenter}
                                        comment={comment.comment}
                                        datetime={`${comment.commentDate} , ${comment.commentTime}`}
                                        commentType={comment.commentType}/>
                                        </div>
                                })}
                            </CommentTable>
                    }
                    </Cards>
                    </div>
                    {this.Message()}
                    {this.showDialogBox()}
                    </div>

        );
    }
    }
    private showDialogBox = () =>{
        return <DialogBox className="feedbackform-dialogBox"
          onClick1 = {()=>this.submit("re-open")} onClick2 = {()=>this.setState({open: false})} 
          open={this.state.open} title={Message.Confirmation}
          maxWidth={"xs"} buttonClass="inbox-dialog-button ml-3 mr-1 button-color" fullWidth={true} 
          showDefaultButton={true} content={Message.reopen_confirm}
          defaultButtonVariant="contained" >
          </DialogBox>
      }
    private cancle = () => {
        this.setState({ isApproved:false, isEdit:false, changeStatus: false,ticketdetail: {}});
    }
    private submit = (params: any) => {
            this.setState({btnloader: true});
            Asf.api.post("/tickets/create", { ...this.state.ticket })
                .then((res: any) => {
                        Asf.nav.navTo("/ticket");
                }).catch((err: any) => {
                    this.setState({ hasError: err.exMessage, btnloader:false});
                });
    }
    private Message = () =>{
    return (<Snackbar css={this.state.showButton?"snackbarSuccess":"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
                action={<div>
                <span> {(this.state.showButton)?
                <span>
                <Button className="sign-in-button ml-2" variant="outlined" onClick={()=>Asf.nav.navTo("/ticket/create-ticket")}>{"Yes"}</Button>
                <Button className="sign-in-button" variant="outlined" onClick={()=>Asf.nav.navTo("/ticket")}>{"No"}</Button></span>
                :<IconButton key="close" color="inherit"  onClick={this.handleSnackbarClose}><FaTimes/></IconButton> } </span> 
            </div>}
                open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage?this.state.errMessage:Message.Ticket_save_message}/>);
      }
    private handleSnackbarClose = () =>{
        this.setState({isOpen:false,errMessage:""});
    } 
    
    private handleEdit = () => {
        if (this.state.ticket && (this.state.ticket.resolution === Message.Closed || this.state.ticket.resolution === Message.InProgress)) {
            this.setState({ hasError: Message.Edit_button_err_message,clickedoption:"handleEdit" });
        } else {
            this.setState((prevState: any) => ({
                // isEdit: !prevState.isEdit,
                isEdit: true,
                clickedoption:"handleEdit",
                ticketdetail: {},
            }));
            this.setState({ hasError: undefined ,clickedoption:"handleEdit"});
        }
    }
    private handleComment = (click:string) => {
        if (this.state.ticket && this.state.ticket.resolution === Message.Closed) {
            this.setState({ hasError: Message.Closed_err_message ,clickedoption: click});
        } else {
            this.setState({
                visible: true,
                clickedoption: click,
                isEdit: false,
                hasError: "",
                changeStatus: false,
                isApproved: false,
            });
        }
    }
    private closeModal = () => {
        this.setState({
            visible: false,
            clickOption: "",
            error: {},
            changeStatus: false,
            isApproved: false,
            ticketdetail: {},
        });
    }
    private updateFormFieldValue = (value: any, name: any) => {
        const state = this.state;
        let ticketdetail = state.ticketdetail;
        ticketdetail = {
            ...ticketdetail,
            [name]: value,
        };
        this.setState({ ticketdetail: ticketdetail });

    }
    private updateReactSelect = (value: any, name: any ) => {
        const state = this.state;
        let ticketdetail = state.ticketdetail;
        ticketdetail = {
            ...ticketdetail,
            [name.name]: value.value,
        }
        this.setState({ ticketdetail: ticketdetail });
    }

    private addComment = () => {
        if (this.state.btnloader) {
            return;
        }
        if(this.validate(this.state.ticketdetail)){
        this.setState({btnloader: true});
        let comment = this.state.ticketdetail && this.state.ticketdetail.comment ;
        let data = {
            comment,
            commentType: "COMMENT",
        };
        Asf.api.post(`/comments/create/${this.props.ticketId}`, data)
            .then((res: any) => {
                if (res) {
                    const state = this.state;
                    let ticketdetail = state.ticketdetail;
                    ticketdetail = {
                        ...ticketdetail,
                        comment: "",
                    };
                    this.setState({ ticketdetail: ticketdetail,btnloader:false });
                    
                        if(this.state.clickOption === Message.Closed){
                            const state = this.state;
                            let ticketdetail = state.ticketdetail;
                            ticketdetail = {
                                ["resolution"]: "CLOSED",
                            }
                            this.setState({ticketdetail: ticketdetail,clickOption:""}, () => {
                                this.update();
                                this.commentFilter("ALL");
                                this.closeModal();
                            });
                        }else{
                            this.commentFilter("ALL");
                            this.closeModal();
                        }
                }
            }).catch((err: any) => {
                let ticketdetail = this.state.ticketdetail;
                    ticketdetail = {
                        ...ticketdetail,
                        comment: "",
                    };
                this.setState({ticketdetail: ticketdetail,errMessage:err.exMessage,isOpen: true ,btnloader:false,clickOption:""});
            });
        }
    }
    private isApproved = () => {
        if(this.state.ticket && this.state.ticket.status === Message.Closed){
            this.setState({ hasError: Message.Ticket_Already_closed });
        }else{
        this.setState({ isApproved: true,clickedoption:"isApprove" });
        }
    }
    // private handleDecline = () => {
    //     this.setState({clickedoption:"handleDecline"},()=>this.handleClose(this.state.clickedoption));
    // }
    private handleClose = (click:any) => {
        if (this.state.ticket && this.state.ticket.resolution === Message.Closed) {
            this.setState({ hasError: Message.Ticket_Already_closed ,clickedoption: click});
        }else {
            this.setState({clickedoption: click , clickOption: Message.Closed , isEdit: false , hasError:""},()=>{
            this.handleComment(click)});
        }
    }
    private validate = (ticketdetail:any) => {
        let error = this.state.error && this.state.error; 
        if (ticketdetail.title !== undefined) {
            if (ticketdetail.title.trim() === "") {
                error = {
                    titleError : Message.Ticket_title,
                }
            }
            else if (!Utils.wordLimit(ticketdetail && ticketdetail.title ? ticketdetail.title.toString().trim() : ticketdetail.title)) {
                error.titleError = Message.Session_title_validation_error;
              }
            else{
                error.titleError = "";
            }
        }
        
        if (ticketdetail.description !== undefined) {
            if (ticketdetail.description.trim() === "") {
                error.descriptionError = Message.Ticket_discription;
            }
            else if (!Utils.validateCharacterSize300(ticketdetail.description && ticketdetail.description.trim())) {
                error.descriptionError = Message.Ticket_discription_length;
            }else{
                error.descriptionError = "";
            }
        }
        if (this.state.visible) {
            if ((!ticketdetail.comment) || ticketdetail.comment.trim() === "") {
                error.commentError = Message.Ticket_comment;
            }
            else if (!Utils.validateMinLength10(ticketdetail.comment && ticketdetail.comment.trim())) {
                error.commentError = Message.Ticket_comment_length;
            }else{
                error.commentError = "";
            }
        }

        if(this.state.changeStatus){
            if(ticketdetail.resolution === undefined){
                error.resolutionError = Message.Ticket_resolution;
            }
        }
        if(this.state.isApproved){
            if(ticketdetail.assignedTo === undefined){
                error.assignedToError = Message.Ticket_resolution;
            }
        }
        this.setState({ error: error });
        for (let [key, value] of Object.entries(error)) {
            if(value !== ""){
            console.log(`${key}: ${value}`);
                return false;
            }
          }
        return true
        
    }
    private update = () => {
        if (this.state.btnloader) {
            return; 
        }
        let isValid;
        if(this.state.isEdit || this.state.changeStatus || this.state.isApproved){
            if(Object.keys(this.state.ticketdetail ? this.state.ticketdetail: {}).length !== 0){
                isValid = this.validate(this.state.ticketdetail);
            }else{
                isValid = false;
                this.setState({hasError:"No modification to update"});
            }
             
        }else{
            isValid = true;
        }
        if(isValid){
        this.setState({btnloader: true});
        const ticketdetail = this.state.ticketdetail;
        Asf.api.patch(`/tickets/${this.props.ticketId}/update`, ticketdetail )
            .then((res: any) => {
                this.setState({ hasError: Message.Ticket_updated ,isEdit: false,changeStatus:false ,btnloader:false,isApproved: false ,ticket:res , ticketdetail: {}});
                this.commentFilter("ALL");
                // Asf.nav.navTo("/ticket");
            }).catch((err: any) => {
                this.setState({errMessage:err.exMessage,isOpen: true,btnloader:false ,isApproved: false});
            });
        }
    }
    private reOpen = () => {
        if (this.state.ticket &&  this.state.ticket.resolution !== Message.Closed) {
            this.setState({ hasError: Message.Ticket_Already_open ,clickedoption:"reOpen"});
        } else {
            this.setState({open:true,clickedoption:"reOpen"});
        }

    }
    private commentFilter = (filter:"ALL"|"WORKLOG"|"COMMENT") => {
        this.setState({ isSelected:filter, commentLoad: true });
        Asf.api.get( `/comments/get/${this.props.ticketId}?commentType=${filter}`)
        .then((_res: any) => {
            let _comments = _res.Comments;
            let comments = this.state.comments;
            comments.rows = _comments;
            this.setState({comments: comments, commentLoad: false});
        })
        .catch((err: any) => {
            if(err.status){
            this.setState({errMessage:err.exMessage,isOpen: true, commentLoad: false});
            }
            else{
            this.setState({errMessage:Message.Err_Message,isOpen: true, commentLoad: false}); 
            }
        });
    }
} 