import React from 'react';
import { Button, Cards, Spinner, Chart, DataGrid } from '@my-org/my-monorepo-ui-lib';
import { FeedbackHeader } from '../../components';  
import { Message } from "../../common";
import './Feedbacks.scss';
import {response, questions} from '../../Sample-json/FeedbackData';
import { Asf } from '@my-org/inno-services';


interface IFeedbackProps{
    sessionId: any;
}
interface IFeedbackState{
    loading: boolean;
    overallrating: any[];
    chartData2: any[];
    data: any[];
    row:any;
    
}
export class Feedbacks extends React.Component<IFeedbackProps,IFeedbackState> {
   
        state = {
            column : [{
                headerName: "S.N.", field: "SNo",sortable: true,filter: true, width:180
            }, {
                headerName: "Name", field: "Name",sortable:false,filter: false, width:350
            }, {
                headerName: "Overall Rating", field: "Rating",sortable: false,filter: false, width:350
            }
            ],
            row: [],
            overallrating : [],
            data : [],
            chartData2: [],
            loading:true,
          }
         
          public componentDidMount(){
            let overallRating;
            let answer:object[] =[];
            Asf.api.get(`/session/${this.props.sessionId}/feedback/rating`)
            .then((res: any) => {
                if(res){
                  for( let i=0 ; i<10; i++ ){
                      answer.push(res[i].rating)
                  }
                  overallRating = res[10].rating;
                  this.setState({overallrating:overallRating, chartData2:res, data:answer},()=>this.setState({loading:false}));
                }
            }).catch((err:any) => {
                Asf.logger.log(err);
                this.setState({loading:false});
            })
            Asf.api.get(`/session/${this.props.sessionId}/feedbacks`)
            .then((res: any) => {
            let rowdata;
            let row: object[] = [];
            let sno = 1;
                res.map((result: any) => {
                    rowdata = {SNo: 0, Name: "", Rating: "", session: "", id: 0}
                            rowdata.SNo = sno++;
                            rowdata.Name = result.feedbackBy.firstName+" "+result.feedbackBy.lastName;
                            rowdata.Rating = result.quesAns[10].ans;
                            rowdata.session = result.session.id;
                            rowdata.id = result.feedbackBy.id;
                            row.push(rowdata);
                            
                        });
                        this.setState({row: row},()=>this.setState({loading:false}));
            }).catch((err: any) => {
            
            });
        }
    public render() {
        if (this.state.loading) {
            return <Spinner className="ml-20"/> ;
        }
        else{
        return (
            <div className="Feedback-container display-flex h-90">
                <div className="Feedback-head-container display-flex mt-4">
                    <Cards className="Feedback-head-card">
                        <FeedbackHeader sessionId={this.props.sessionId} />
                    </Cards>
                </div>
                <div className="inner-container display-flex">
                <div className="chart-container">
                <div className="chart-container display-flex text-center mt-5">
                    
                    <Cards className="inner-chart-container-1 ml-1">
                        <h3>Overall Review</h3>
                        <Chart type="bar" name="Overall Review" className="chart-2" id="chart-2" data={this.state.chartData2} width={460} height={240}
                            margin={{ top: 5, right: 15, left: 0, bottom: 5,}}  xKey={this.state.overallrating}  response={response} barcolor={"#338AFD"}>
                        </Chart>
                    </Cards>
                    <Cards className="inner-chart-container-2 mr-1">
                        <h3>Question's Feedback</h3>
                        <Chart type="bar"name="Feedbacks" className="chart-3 pr-8" id="chart-3" data={this.state.chartData2} width={460}
                              height={240} margin={{ top: 5, right: 15, left: 0, bottom: 5,}} tooltipClassName="tooltip-chart"
                             xKey={'response_value'}  response={questions} tooltipWrapperClass="tooltip-container" barcolor={"#338AFD"}>
                        </Chart>
                    </Cards>
                </div>
                </div>
                </div>
                <div className="Feedback-container display-flex">
                <div className="submitted-feedback-container mt-5 mr-3 ml-3">
                <div className="submittedFeedback-container">
                    <DataGrid className="data-grid" columns={this.state.column} rows={this.state.row}
                    onRowSelect={this.onRowSelect}  />
                </div>
                </div>
                </div>
            </div>
        );
    }
}
    private onRowSelect = (selectedRows: any) => {
        const selectedRow = selectedRows && selectedRows[0];
        console.log("selectrow",selectedRow)
        if (selectedRow) {
                Asf.nav.navTo(`/feedback/report/${selectedRow.session}/filled-feedback-form/${selectedRow.session}/${selectedRow.id}`);
        }
    }
}

