import React from "react";
import { Label, Button, MToolTip } from "@my-org/my-monorepo-ui-lib";
import {Asf} from "@my-org/inno-services";
import { Message } from "../../common";
import "./SessionHead.scss";

interface ISessionHeadProps{
    detail: any;
}
export class SessionHead extends React.Component<ISessionHeadProps, {}>{

    handleClick = () => {
        Asf.nav.navTo("/active-feedback/provide-feedback-form/"+ this.props.detail.session);
    }
    render(){
        return(

         <div className="active-session-head">
            <div className="session-head-detail">  
                <div className="card-head-label"><Label content={`Session`}/></div>
                <div className="card-head-data">{this.props.detail.session}</div>
            </div>
            <div className="session-head-detail">
                <div className="card-head-label"><Label content={`Topic`}/></div>
             <div className="card-head-data">{this.props.detail.title}</div>              
                </div>
            <div className="session-head-detail">
                <div className="card-head-label"><Label content={`Presenters`}/></div>
                <div className="card-head-data">{this.props.detail.Presented}</div>
            </div>
            <div className="session-head-detail">  
                <div className="card-head-label"><Label content={`Date`}/></div>
               <div className="card-head-data">{this.props.detail.date}</div>
                 </div>
            <div className="session-head-detail">  
                <div className="card-head-label"><Label content={`Time`}/></div>
               <div className="card-head-data">{this.props.detail.time}</div>
                 </div>
            <div className="session-head-detail">
                <div className="card-head-label"><Label content={`Duration`}/></div>
               <div className="card-head-data">{this.props.detail.duration}</div>
            </div>
            <div className="session-head-detail">
                <div className="card-head-label"><Label content={`Location`}/></div>
                <div className="card-head-data">{this.props.detail.location}</div>
            </div>
            <div>{ (this.props.detail.feedbackEnabled)?
                <Button className={`primary-btn button-color w-12 mt-4`} variant="contained" btnColor="primary" onClick={this.handleClick} disabled={(this.props.detail.feedbackEnabled)?false:true } >{Message.Provide_feedback_button}</Button>
                :<MToolTip title="This session is not enabled yet" id="disable-tooltip" placement="right">         
                <span>  
                    <Button className={`primary-btn cursor-default w-12 mt-4 disabled disabled-button`} variant="contained" btnColor="primary">{Message.Provide_feedback_button}</Button>
                </span>
             </MToolTip>           
            } 
              </div>  
        </div>
        );
    }
} 