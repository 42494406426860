import React from 'react'; //import PropTypes from "prop-types";

import { FormConsumer } from './Form';
import { InputField, Label } from '..';
import "./Form.scss";
import { ReactSelect } from '../react-select/ReactSelect';
var yourChips = [];
export var FormInput = function FormInput(props) {
  return React.createElement(FormConsumer, null, function (_ref) {
    var values = _ref.values,
        setValue = _ref.setValue;

    if (props.layout === 1) {
      return React.createElement("div", {
        className: "div-margin"
      }, React.createElement(Label, {
        className: "".concat(props.labelclassname, " form-labels"),
        content: props.label && props.label,
        required: props.requiredField
      }), React.createElement(InputField, {
        name: props.name,
        type: props.type,
        className: "wms-ui-InputField form-inputfield ".concat(props.inputClassName),
        placeholder: props.placeholder,
        required: props.required,
        variant: props.variant,
        multiline: props.multiline,
        disabled: props.disabled,
        label: props.Inputlabel,
        value: props.value,
        margin: props.margin,
        selectedDate: props.selectedDate,
        id: props.id,
        onChange: function onChange(event) {
          //event.preventDefault();
          setValue(props.name, event);
          {
            props.onChange ? props.onChange(props.name, event) : null;
          }
          ;
        }
      }));
    }

    if (props.layout === 2) {
      return React.createElement("div", {
        className: "div-margin"
      }, React.createElement(Label, {
        className: props.labelclassname,
        content: "".concat(props.label),
        required: props.requiredField
      }), React.createElement("br", null), React.createElement(InputField, {
        name: props.name,
        type: props.type,
        className: "wms-ui-InputField ".concat(props.inputClassName),
        placeholder: props.placeholder,
        required: props.required,
        variant: props.variant,
        multiline: props.multiline,
        disabled: props.disabled,
        label: props.Inputlabel,
        value: props.value,
        onChange: function onChange(event) {
          //event.preventDefault();
          setValue(props.name, event);
          {
            props.onChange ? props.onChange(props.name, event) : null;
          }
          ;
        }
      }));
    }

    if (props.layout === 3) {
      return React.createElement("div", {
        className: "".concat(props.chipDiv, " div-margin")
      }, React.createElement(Label, {
        className: "".concat(props.labelclassname, " form-labels"),
        content: "".concat(props.label),
        required: props.requiredField
      }), React.createElement(ReactSelect, {
        className: "".concat(props.inputClassName, " form-inputfield"),
        isMulti: props.isMulti,
        name: props.name,
        onChange: function onChange(event) {
          //event.preventDefault();
          setValue(props.name, event);
          {
            props.onChange ? props.onChange(props.name, event) : null;
          }
          ;
        },
        defaultValue: props.defaultValue,
        placeholder: props.placeholder,
        options: props.options,
        value: props.value
      }));
    }
  });
}; // Input.propTypes = {
//     name: PropTypes.string.isRequired
// };