const NavHead = (state = 'Dashboard', action:any) => {
  // if(action.title){
  //   switch (action.type) {
  //     case 'SIDEBAR_CLICK':
  //       return action.title
  //     default:
  //       return state
  //   }
  // }else 
  //if(action.info){
    switch (action.type) {
      case 'PATH':
        return (action.info);
      default:
        return state
     }
  // }else{
  //   return state
  // }
  }
export default NavHead;