import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import './ModalComponent.scss';
export var ModalComponent = function ModalComponent(props) {
  return React.createElement("div", {
    className: "wms-ui-modalComponent ".concat(props.className)
  }, React.createElement(Modal, {
    "aria-labelledby": "transition-modal-title",
    "aria-describedby": "transition-modal-description",
    open: props.open,
    onClose: props.handleClose,
    closeAfterTransition: true,
    BackdropComponent: Backdrop,
    BackdropProps: {
      timeout: 500
    }
  }, React.createElement("div", {
    className: 'paper'
  }, React.createElement("h2", {
    id: "transition-modal-title"
  }, props.header), React.createElement("div", {
    id: "transition-modal-description"
  }, props.children))));
};