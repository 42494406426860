import { CircularProgress as MCircularProgress } from "@material-ui/core";
import React from "react";
import "./spinner.scss";
export var Spinner = function Spinner(props) {
  var color = props.color,
      variant = props.variant,
      className = props.className;
  return React.createElement(MCircularProgress, {
    className: "spinner ".concat(className),
    color: color,
    variant: variant
  });
};