import React from "react";
import { FaCheck } from 'react-icons/fa';
import { Message } from '../../common';

export const FbRow: React.FC<any> = (props: React.PropsWithChildren<any>) => {
    let count = 1;
    return (
        <tr>
            <td>{props.index + 1}</td>
            <td className="w-td-ques">{props.fbQues.ques}</td>
            <td>{props.fbQues.ans === Message.Strongly_Agree ? <FaCheck /> : null}</td>
            <td>{props.fbQues.ans === Message.Agree ? <FaCheck /> : null}</td>
            <td>{props.fbQues.ans === Message.Neutral ? <FaCheck /> : null}</td>
            <td>{props.fbQues.ans === Message.Disagree ? <FaCheck /> : null}</td>
            <td>{props.fbQues.ans === Message.Strongly_Disagree ? <FaCheck /> : null}</td>
        </tr>
    );
};
