import React, { Component } from 'react';
import './WmsInbox.scss';
import { DataGrid, Button, TypographyComponent, Spinner, DropDown, MToolTip, InputField } from '@my-org/my-monorepo-ui-lib';
import {Asf, Utils} from "@my-org/inno-services";
import { Message } from "../../common";

export interface InboxState {
  loading:boolean;
  tickets?: any;
  userType?: any;
  isSelected:"Self"|"OPEN"|"ASSIGN_TO_ME"|"ALL"|"SNA"|"ADMIN"|"HR"|"Open"|"Assigned to me";
  filter:string;
  searchValue: string;
}
export class Wms_Inbox extends Component<{}, InboxState> {
  constructor(props: InboxState) {
    super(props);
    this.state = {
      loading: true,
      searchValue: "",
      tickets: {
        columns: [
          {
            headerName: 'Ticket id',
            field: 'id',
            sortable: true,
            lockPosition: true,
          },
          {
            headerName: 'Title',
            field: 'title',
            sortable: true,
            tooltip: (params: any) =>  params.data.title,
            filter:true,
            lockPosition: true,
          },
          {
            headerName: 'Priority',
            field: 'priority',
            lockPosition: true,
          },
          {
            headerName: 'Status',
            field: 'status',
            sortable: true,
            lockPosition: true,
          },
          {
            headerName: 'Assigned',
            field: 'userName',
            sortable: true,
            lockPosition: true,
          },
          {
            headerName: 'Created',
            field: 'created',
            sortable: true,
            width:220,
            lockPosition: true,
          },
          {
            headerName: 'Updated',
            field: 'updated',
            sortable: true,
            width:220,
            lockPosition: true,
          }
        ], rows: []
      },
      filter: "CreatedByMe",
      isSelected: "Self",
      userType: {}
    }
  }

  public componentDidMount() {

    //api cCreatedByMe 

    
    const res = Utils.localstorage("get","res");
    this.setState({ userType: res && res.userType });
    Asf.api.get("/tickets/user?filter=CreatedByMe")
      .then((res: any) => {
        if (res) {
          let _ticket = res && res.Tickets;
          var tickets = this.state.tickets;
          var rows = tickets.rows.concat(_ticket);
          if (rows[0] === null) {
            tickets.rows = [];
          } else {
            tickets.rows = rows;
          }
          this.setState({ tickets: tickets,isSelected:"Self" },()=>{this.setState({ loading: false });});
        }
      }).catch((err: any) => {
        //success
      })
  }
  // set data in data grid
  private setdata = (res:any) => {
    this.setState({ loading: true });
    let _ticket = res;
    let tickets = this.state.tickets;
    tickets.rows = _ticket.Tickets;
    this.setState({ tickets: tickets },()=>{this.setState({ loading: false });});
  }

  //filters for datatable

  private filter = (filter: any, value: any) => {
    this.setState({isSelected: filter,filter: value});
    Asf.api.get(`/tickets/user?filter=${value}`)
      .then((res:any) => {
        if (res) {
          this.setdata(res);
        }
      }).catch((err:any) => {
        //success
      })
  }
  private handleCreateTicket = () => {
    Asf.nav.navTo('/ticket/create-ticket');
  }
  render() {
    const filters = [
      {   id: 1,
          value: "Self",
          onclick: () => this.filter("Self", "CreatedByMe"),
      },
      {
          id: 2,
          value: "Open",
          onclick: () => this.filter("Open", "Open"),
      },
      {
          id: 3,
          value: (this.state.userType !== 'EMP' && this.state.userType !== 'SUPER_ADMIN') ? "Assigned to me" : null,
          onclick: () => this.filter("Assigned to me", "AssignedToMe"),
      },
      {
        id: 4,
        value: this.state.userType === 'SUPER_ADMIN' ? "All" : null,
        onclick: () => this.filter("ALL", "All"),
      },
      {
        id: 5,
        value: this.state.userType === 'SUPER_ADMIN' ? "SNA" : null,
        onclick: () => this.filter("SNA", "SNA"),
      },
      {
        id: 6,
        value: this.state.userType === 'SUPER_ADMIN' ? "ADMIN" : null,
        onclick: () => this.filter("ADMIN", "ADMIN"),
      },
      {
        id: 7,
        value: this.state.userType === 'SUPER_ADMIN' ? "HR" : null,
        onclick: () => this.filter("HR", "HR"),
      },

  ]
  if (this.state.loading) {
      return <Spinner className="ml-15"/> ;
  }else{
    return (
      <div className="wms-inbox p-4">
        <div className="fliter-option">
          <DropDown className="" classbtn={"wms-inbox-filter"} id="dropdown" value={filters}  label={this.state.isSelected} />
          {/* <MToolTip title="Search by title & presenter" id="tooltip-1">
            <div>
            <InputField  className="inbox-search-hr" label="search" onChange={this.handleSearch} value={this.state.searchValue} />
            </div>
          </MToolTip> */}
          <Button className="create-ticket-btn button-color float-right"
            variant="contained"
            onClick={this.handleCreateTicket}
            btnColor="primary">Create Ticket
          </Button>
        </div>
        <div>
        {/* <div className="fliter-option">
          <div className="display-flex">
          {this.state.userType === 'SUPER_ADMIN' ?
          <div>
          <Button className={this.state.isSelected === "ALL"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("ALL", "All")}>ALL
          </Button>
          <Button className={this.state.isSelected === "SNA"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("SNA", "SNA")}>SNA
          </Button>
          <Button className={this.state.isSelected === "ADMIN"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("ADMIN", "ADMIN")}>ADMIN
          </Button>
          <Button className={this.state.isSelected === "HR"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("HR", "HR")}>HR
          </Button>
          </div>
          :undefined}
          <Button className={this.state.isSelected === "Created By Me"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("CreatedByMe","CreatedByMe")}>CREATED BY ME
          </Button>
          <Button className={this.state.isSelected === "Open"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("Open", "Open")}>OPEN
          </Button>
          {(this.state.userType !== 'EMP' && this.state.userType !== 'SUPER_ADMIN') ? 
          <Button className={this.state.isSelected === "AssignedToMe"?"active tms-inbox-button":"tms-inbox-button"}
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={() => this.filter("AssignedToMe","AssignedToMe")}>ASSIGN TO ME
          </Button>
          :undefined}
          </div>
          <div className="display-flex">
          <Button className="tms-inbox-button create-ticket-filter"
            size="medium"
            variant="outlined"
            btnColor="default"
            onClick={this.handleCreateTicket}>Create Ticket
          </Button>
          </div>
        </div> */}
        <DataGrid className="data-grid min-h-72 cursor-pointer ag-row ag-theme-balham .ag-header mt-6" columns={this.state.tickets.columns} rows={this.state.tickets.rows} onRowSelect={this.onRowSelect}
        noRowsTemplate={Message.No_tickets_found} pagesize={12}/>
        </div>
      </div>
    )
  }
}

  private onRowSelect = (selectedRows: any) => {

    var selectedRecord = selectedRows && selectedRows[0];

    if (selectedRecord) {
      Asf.nav.navTo(`/ticket/update-ticket/${selectedRecord.id}/${this.state.filter}`);
    }
  }
}