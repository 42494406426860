import React from "react";
import Collapse from "@material-ui/core/Collapse";
import { MdExpandMore, MdExpandLess} from 'react-icons/md';
import {Link, BrowserRouter} from 'react-router-dom';
import {Asf} from '@my-org/inno-services';
import './Sidebar.scss';
import { Image } from '@my-org/my-monorepo-ui-lib';
import { connect } from "react-redux";
import { onSidebarCLick } from "../../redux/action";

interface sidebarItem{
    item: any,
    onClickSidebar: (val:string) => any,
    sideBarLabel: any
}
const SidebarItem: React.SFC<sidebarItem> = (props) => {
    const [collapsed, setCollapsed] = React.useState(true);
    const { label, path, icon, children } = props.item;
    const sideBarLabel = props.sideBarLabel;

    function toggleCollapse() {
      setCollapsed(prevValue => !prevValue);
    }
  
    function onClick(e: any, path: string) {
      // props.onClickSidebar(e.target.innerText);
      if(path === "/logout"){
        // Webapi.sendWithToken('Post','users/logout',)
        // .then(res =>{
        // localStorage.removeItem('res');
        // document.location.pathname = '/';
        // }).catch(err => {
        //       success
        //     console.error(err);
        //   })
        Asf.auth.logout();
        // .then((res: any) => {
        //   localStorage.removeItem("res");
        //   props.onClickSidebar("Dashboard");
        //   Asf.nav.navTo("/login");
        // }).catch((err: any) => {
          
        // });
        // localStorage.removeItem("res");
        // props.onClickSidebar("Dashboard");
        // Asf.nav.navTo("/login");
      }
      else if(Array.isArray(children)) {
        toggleCollapse();
      }else{
          Asf.nav.navTo(path);
          props.onClickSidebar(e.target.innerText);
      }
    }
  
    let expandIcon;
  
    if (Array.isArray(children) && children.length) {
      expandIcon = !collapsed ? (
        <MdExpandLess
          className={
            "sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded font-weight-bold"
          }
        />
      ) : (
        <MdExpandMore className="sidebar-item-expand-arrow" />
      );
    }
    return (
      <>
        <li className="sidebar-li display-flex" onClick={(e) => onClick(e, path)}>
            <BrowserRouter>
            <div className={`wms-ui-sidebar-a p-3 font-weight-500 ${sideBarLabel === label ? "active-a" : ""}`}>
            <span className="sidebar-span display-inherit">
                <div className="sidebar-icon-div display-flex">
                    {icon && icon}
                </div>
                    {label}
                
                </span>
                {expandIcon}
            </div>
            </BrowserRouter>
        </li>
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
          {Array.isArray(children) ? (
            <ul className="sidebar-ul sidebar-ul-sub m-0 p-0 mb-2 pt-2 pb-2">
              {children.map((subItem: any, index) => (
                <React.Fragment key={`${subItem.label}${index}`}>
                    <SidebarItem
                      item={subItem}
                      onClickSidebar={props.onClickSidebar}
                      sideBarLabel={sideBarLabel}
                    />
                </React.Fragment>
              ))}
            </ul>
          ) : null}
        </Collapse>
      </>
    );
  }

  export interface ISidebarProps {
    routes?: any;
    className?: string;
    aclassName?: string;
    src?: string;
    altName?: string;
    sideBarClick?: (val:string)=> any
    children?: any;
    sideBarLabel: any;
}
 class Sidebar extends React.Component<ISidebarProps, {}> {
  sideBarClick = (value: string) => {
    this.props.sideBarClick && this.props.sideBarClick(value);
  }
  render(){
  return (
            <React.Fragment>
            <div className={`wms-ui-sidebar-container display-flex ${this.props.className}`}>
            <Image src={this.props.src}  altName={this.props.altName} className="wms-header-logo m-6"/>
                <div className="sidebar-inner-container display-flex mt-5">
                <ul className="sidebar-ul m-0 p-0 mb-2 pt-2 pb-2">
                {this.props.routes.map((sidebarItem: any, index: number) => (
                    <React.Fragment key={`${sidebarItem.name}${index}`}>
                        <SidebarItem
                            item={sidebarItem}
                            onClickSidebar={this.sideBarClick}
                            sideBarLabel={this.props.sideBarLabel}
                        />
                    </React.Fragment>
                    ))}
                </ul>
                </div>
            </div>
           <main className="wms-main min-h-90 background-color">
            <div>
                {this.props.children}
            </div>
            </main>
            </React.Fragment>
        );
                }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    sideBarClick: (value:string) => dispatch(onSidebarCLick(value))
  }
}
const mapStateToProps = (state: any) => (
  {
  sideBarLabel: state.SideBar
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
