import { Utils } from "@my-org/inno-services";

const SideBar = (state = Utils.localstorage("get", "sidebarClick", "Dashboard"), action:any) => {
    // if(action.title){
    //   switch (action.type) {
    //     case 'SIDEBAR_CLICK':
    //       return action.title
    //     default:
    //       return state
    //   }
    // }else 
    if(Utils.localstorage("get", "sidebarClick", "Dashboard")){
    state = Utils.localstorage("get", "sidebarClick", "Dashboard")
    }else{
      state = "Dashboard";
    }
      switch (action.type) {
        case 'SIDEBAR_CLICK':
          Utils.localstorage("set", "sidebarClick", action.sideBarLabel);
          return (action.sideBarLabel);
        default:
          return state;
      }
    }
  export default SideBar;