import * as React from 'react';
import { MdDashboard, MdFeedback, MdInbox, MdPersonAdd } from 'react-icons/md';

export const EmpRoutes =  [
    { path: "/dashboard", label: "Dashboard"},
  { path: "/ticket", label: "Ticket System"},
  { path: "", label: "Feedback",
children: [{ path: "/active-feedback", label: "Active Feedback"},
{ path: "/feedback", label: "Submitted Feedback"}]},
{ path: "/logout", label: "Logout"}]

export const HrRoutes = [
  { path: "/dashboard", label: "Dashboard"},
  { path: "/ticket", label: "Ticket System"},
  { path: "/feedback", label: "Feedback System"},
  { path: "/registration-form", label: "New User Register"},
  { path: "/logout", label: "Logout"}]