import React from "react";
import { InputField, Button, Cards, Snackbar, ButtonLoader } from "@my-org/my-monorepo-ui-lib";
import { Asf, Utils } from "@my-org/inno-services";
import { Message } from "../../common";
import "./Login.scss";
import { Link, BrowserRouter, Route, Switch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { FaTimes } from "react-icons/fa";
import { Common } from "../../common/common";

export interface LoginState {
  user?: any;
  passwordError?: string;
  hasError?: string;
  isOpen?: boolean;
  errMessage?: string;
  loading?: boolean;
  passwordtype: string;
  showPassword: boolean;
}

export class Login extends React.Component<{}, LoginState> {
  // loginForm = React.createRef<HTMLDivElement>();
  constructor(props: LoginState) {
    super(props);
    this.state = {
      showPassword: false,
      passwordtype: "password",
      loading: false,
      user: {
        email: "",
        password: ""
      },
      isOpen: false,
      errMessage: "",
    };
  }
  public componentDidMount() {
    // if(this.loginForm.current ){
    // window.onkeyup = (ev: KeyboardEvent): any => {
    //   if(ev.key === 'Enter'){
    //   this.handleLogin(ev);
    //   }
    //   }
    // }
  }

  render() {
    return (
      <div className="main-login-container h-100 display-flex loginForm icon-left">
        <div className="login-container h-100 display-flex loginForm icon-right w-100">
          <div className="login-container h-100 display-flex loginForm icon-right">
            <form onSubmit={this.handleLogin} className="loginForm" >
              <div className="card-container">
                <div className="card-login text-center">
                  <div className="card-header font-weight-600 mt-4 mb-4">

                    <img src={require("../../assets/images/Logo_login.png")}></img>
                    {/* <span className="logo_title mt-4"> {Message.Login_heading} </span> */}
                  </div>
                  {
                    this.state.hasError &&
                    <div className="loginError errorTab p-4">{this.state.hasError}</div>
                  }
                  <div className="card-body mt-4">
                    {/* <div className="login-input mt-4"><div className="w-8 mt-2">Email</div> */}
                    <InputField
                      name={"email"}
                      value={this.state.user && this.state.user.email}
                      variant="outlined"
                      placeholder="Email"
                      onChange={this.handleChange}
                      className="w-22 mt-4"

                    />
                    {/* </div> */}
                    {/* <div className="login-input mt-4"><div className="w-8 mt-2">Password</div> */}
                    <InputField
                      name={"password"}
                      value={this.state.user && this.state.user.password}
                      variant="outlined"
                      label="Password"
                      showPassword={this.state.showPassword}
                      handleClickShowPassword={this.handleClickShowPassword}
                      type={this.state.passwordtype}
                      placeholder="Password"
                      onChange={this.handleChange}
                      className="w-22 mt-4"
                    />
                    {/* </div> */}
                    <div style={{ color: "red" }}>{this.state.passwordError}</div>
                    <div className="row">
                      <Button type='submit' className="login_btn button-color w-22 mt-4"
                        variant="contained"

                      >{this.state.loading ?
                        <ButtonLoader type={Common.loadingType}>{Message.loading}</ButtonLoader> : Message.Login_button}
                      </Button>
                    </div>
                    <div className="mt-1 forgot-password float-left" onClick={this.forgot}><u>{Message.forgot_password}</u></div>
                    <div className="mt-1 version float-right">{Message.Version}</div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {this.ErrMessage()}
        </div>
      </div>
    );
  }
  private forgot = () => {
    Asf.nav.navTo("/forgot");
  }

  private handleChange = (value: string, name: string) => {
    let user = this.state.user;
    user = {
      ...user,
      [name]: value,
    }
    this.setState({ user: user });
  };

  private validate = () => {
    if (!Utils.validateEmail(this.state.user && this.state.user.email)) {
      this.setState({ hasError: Message.Email_validation_error });
      return false;
    }
    if (this.state.user && !this.state.user.password) {
      this.setState({ hasError: Message.Password_validation_error });
      return false;
    }

    this.setState({ hasError: undefined });
    return true;
  };

  private handleLogin = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true });
    const isValid = this.validate();
    if (isValid) {
      //Api call
      Asf.auth.login(this.state.user)
        .then((res: any) => {
          Utils.localstorage("set", "res", res);
          Utils.localstorage("set", "sidebarClick", "Dashboard");
          Asf.nav.navTo("/dashboard");
        }).catch((err: any) => {
          if (err.status) {
            this.setState({ errMessage: err.exMessage, isOpen: true, loading: false });
          }
          else {
            this.setState({ errMessage: Message.Err_Message, isOpen: true, loading: false });
          }
        });
    } else {
      this.setState({ loading: false });
    }
  };
  private handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }
  private ErrMessage = () => {
    return <Snackbar css={"snackbarError"} position={{ vertical: "bottom", horizontal: "center" }}
      action={<IconButton key="close" color="inherit" onClick={this.handleClose}><FaTimes /></IconButton>}
      open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage} />
  }

  private handleClose = () => {
    this.setState({ isOpen: false, errMessage: "" });
  }
}