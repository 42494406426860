import { HttpService } from "./HttpService";
import { NavigationService } from "./NavigationService";

var AuthService =
/** @class */
function () {
  function AuthService() {
    this._httpApi = new HttpService("http://54.200.246.135/wms/v1.0/api"); // this._httpApi = new HttpService("http://54.200.246.135/wms/test/v1.0/api");
    // this._httpApi = new HttpService("http://192.168.0.33:5055/v1.0/api");
  }

  AuthService.prototype.login = function (req) {
    var headers = [{
      key: 'platform',
      value: 'browser'
    }, {
      key: 'device',
      value: "TMS_" + Date.now()
    }];
    return this._httpApi.postUser('/login', req, headers);
  };

  AuthService.prototype.logout = function () {
    this._httpApi.post("/users/logout", "").then(function () {
      localStorage.removeItem("res");
      var loginUrl = "/login";
      new NavigationService().redirect(loginUrl);
    });
  };

  AuthService.prototype.forgot = function (email) {
    return this._httpApi.get("/users/forgetPassword?email=" + email);
  };

  AuthService.prototype.reset = function (token, password) {
    return this._httpApi.put("/users/setNewPassword?token=" + token, password);
  };

  AuthService.prototype.register = function (user) {
    return this._httpApi.postUser('/users/register', user);
  };

  return AuthService;
}();

export { AuthService };