import React from 'react';
import './Profile.scss';
import {Button, InputField, Image,ButtonLoader, Spinner, Label, Cards, Snackbar} from '@my-org/my-monorepo-ui-lib';
import {Asf, Utils} from "@my-org/inno-services";
import Typography from '@material-ui/core/Typography';
import { Message } from '../../common';
import { FaUser, FaUserCircle,FaUserAlt, FaUserAstronaut, FaTimes } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { Common } from '../../common/common';
import { userInfo } from 'os';
//import { Utils } from '../../Utils/Utils';

export interface ProfileState {
    user:any;
    isEdit:boolean;
    loading:boolean;
    hasError?: string;
    isOpen?: boolean;
    Message?: string;
    passwordtype: string;
    oldPasswordShow: boolean;
    newPasswordShow: boolean;
    confirmPasswordShow: boolean;
}
interface IMap {     [key: string]: string; }
// interface ProfileProps {
//     onSelect?: (path: any) => any;
// }
export class Profile extends React.Component<{},ProfileState>{
    constructor(props:any) {
        super(props);
        this.state={
            oldPasswordShow: false,
            newPasswordShow: false,
            confirmPasswordShow: false,
            passwordtype: "password",
            loading:true,
            user:{},
            isEdit: false,
            hasError: "",
            isOpen: false,
            Message: "",
        }
    }

    // onSelect = (value: any) => {
    //     this.props.onSelect && this.props.onSelect(value);
    // }

    private updateFormFieldValue = (value:any, name:any) => {
        var state = this.state;
        var user = state.user;
        user = {
            ...user,
            [name]: value,
        }
        this.setState({ user: user });

    };
    private handleSave = () => {
        const isValid = this.validate();
        
        if (isValid) {
        let obj:IMap = this.state.user;
        Object.keys(obj).map(k => obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k]);
        Asf.api.patch('/users/update', { ...obj })
        .then((res: any) => {
            // if (res.status === "Error") {
            //     Asf.logger.error(res.exMessage);
            //     //document.location.pathname = './';
            // } else 
                // this.setState({ Message: res.updateResponse, isOpen:true });
                let ress = Utils.localstorage("get","res");
                ress = {
                    ...ress,
                    "firstName": this.state.user.firstName,
                    "lastName": this.state.user.lastName,
                    "oldPassword": "",
                    "newPassword": "",
                    "password_confirmation": "",
                }
                this.setState({ user: ress , Message: res.updateResponse, isOpen:true});
                Utils.localstorage("set","res",ress);
                // this.onSelect(username);
            
        }).catch((err:any) => {
            if(err.exMessage){                       
            this.setState({isOpen:true, Message: err.exMessage });
            }
            else{
                this.setState({isOpen:true, Message: Message.Err_Message });
            }
        });
      }
    }
    public componentDidMount() {
       Asf.api.get('/users/get/profile')
        .then((res: any) => {
            if (res) {
                var user = res;
                this.setState({ user: user },()=>{
                    this.setState({loading:false});
                });
            }
        }).catch((err: any) => {
            //success
            Asf.logger.error(err.exMessage);
          })
    }
   public render(){
        if (this.state.loading) {
            return <Spinner className="ml-15"/> ;
        }else{
        return(
            <div className="profile-container">
                <Cards className = "w-35 h-70 display-flex mt-8">
                <div className="profile-detail pl-6">
                     <div className="profile-img pl-6" >
                         <FaUserCircle className="h-24 w-20 mt-6 mb-4"/>
                    </div>
                    <div className="p-row h-6 mt-8">
                            <label className="p-label">{Message.FirstName}</label>
                            <p className="p-para">{this.state.user.firstName}</p>
                    </div>
                    <div className="p-row h-6">
                                <label className="p-label">{Message.LastName}</label>
                                <p className="p-para">{this.state.user.lastName}</p>                        
                    </div>
                    <div className="p-row h-6">  
                                <label className="p-label">{Message.Email}</label>
                                <p className="p-para">{this.state.user.email}</p>
                    </div>
                </div>
                </Cards>
                <Cards className = "w-35 h-70 display-flex mt-8 pl-2">
                        <Typography variant="h4" className="mt-2">{Message.EditProfile}</Typography>
                        
                    <div className="p-row h-7 mt-4">
                  <Label content="First Name : "/><InputField
                            name={'firstName'}
                            value={this.state.user.firstName}
                            variant="outlined"
                            placeholder="First Name"
                            onChange={this.updateFormFieldValue}
                            className="ml-9 w-18"
                            />
                    </div>
                    <div className="p-row h-7 ">
                    <Label content="Last Name : "/>
                   <InputField
                            name={'lastName'}
                            value={this.state.user.lastName}
                            variant="outlined"
                            placeholder="Last Name"
                            onChange={this.updateFormFieldValue}
                            className="ml-9 w-18"
                            />
                     </div>
                     <div className="p-row h-7">
                    <Label content="Old Password : "/>
                   <InputField
                            type = "password"
                            name={'oldPassword'}
                            value={this.state.user.oldPassword}
                            showPassword={this.state.oldPasswordShow}
                            handleClickShowPassword={()=>this.handleClickShowPassword('oldPassword')}
                            variant="outlined"
                            placeholder="Old Password"
                            onChange={this.updateFormFieldValue}
                            className="ml-6 w-18"
                            />
                     </div>  
                      
                     <div className="p-row h-7">
                    <Label content=" New Password : "/>
                   <InputField
                            type = "password"
                            name={'newPassword'}
                            value={this.state.user.newPassword}
                            showPassword={this.state.newPasswordShow}
                            handleClickShowPassword={()=>this.handleClickShowPassword('newPassword')}
                            variant="outlined"
                            placeholder="New Password"
                            onChange={this.updateFormFieldValue}
                            className="ml-5 w-18"
                            />
                     </div>  
                     <div className="p-row h-7">
                    <Label content="Confirm Password : "/>
                    <InputField
                            type = "password"
                            name={'password_confirmation'}
                            value={this.state.user.password_confirmation}
                            showPassword={this.state.confirmPasswordShow}
                            handleClickShowPassword={()=>this.handleClickShowPassword('password_confirmation')}
                            variant="outlined"
                            placeholder="confirm Password"
                            onChange={this.updateFormFieldValue}
                            className="ml-1 w-18"
                            />
                     </div>
                     {
                            this.state.hasError &&
                            <div className='text-center error-color ml-18'>* {this.state.hasError}</div>
                        }
                     <Button className='profile-save-btn button-color float-right w-10 mt-6'
                            variant="contained"
                            onClick={this.handleSave}
                            >{Message.save}
                            </Button>
                    </Cards>
                    {this.Message()}
                </div>
            );
        }
        
    }
    private handleClickShowPassword = (InputField: any) => {
        if(InputField === "oldPassword"){
            this.setState({ oldPasswordShow:!this.state.oldPasswordShow});
        }
        else if(InputField === "newPassword"){
            this.setState({ newPasswordShow:!this.state.newPasswordShow});
        }
        else{
            this.setState({ confirmPasswordShow:!this.state.confirmPasswordShow});
        }
      }
    private validate = () => {
        if (!Utils.validateName(this.state.user.firstName)) {
            this.setState({ hasError: Message.FirstName_Validation_error });
            return false;
        }
        if (!Utils.validateName(this.state.user.lastName)) {
            this.setState({ hasError: Message.LastName_Validation_error });
            return false;
        }
        if(this.state.user.newPassword){
            if(!this.state.user.password_confirmation){
            this.setState({ hasError: Message.Enter_Confirm_password });
            return false;
        }
    }
        if ((this.state.user.newPassword) !== (this.state.user.password_confirmation)) {
            this.setState({ hasError: Message.Confirm_Password_validation_error });
            return false;
        }
        const user = this.state.user;
        // delete user.password_confirmation;
        this.setState({ hasError: undefined, user: user });
        return true;
    };
    private Message = () =>{
        return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
                  action={<div>
                   
                  <IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>
                </div>}
                 open={this.state.isOpen} autoHideDuration={3000} message={this.state.Message}/>
      } 
      private handleClose = () =>{
        this.setState({isOpen:false,Message:""});
        // Asf.nav.navTo("/my-profile");
    }
    }

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         onSelect: (User: any) => {
//             const user = User;
//             dispatch(userName(user))
//         }
//     }
// }

// export default connect(
//     null,
//     mapDispatchToProps
// )(Profile);

