import React, { Component } from 'react';
import { InputField, Button, Cards, SelectBox, Label, Snackbar, ReactSelect } from '@my-org/my-monorepo-ui-lib';
import "./Registration.scss";
import {Asf, Utils} from "@my-org/inno-services";
import { Message } from '../../common';
import IconButton from '@material-ui/core/IconButton';
import { FaTimes } from 'react-icons/fa';
const userType = [
    {label:"Super Admin", value:"SUPER_ADMIN"},
    {label:"Admin", value:"ADMIN"},
    {label:"SNA", value:"SNA"},
    {label:"HR", value:"HR"},
    {label:"EMP", value:"EMP"}
]
const userDesignation = [
    {label:" Software Trainee", value:"SOFTWARE_TRAINEE"},
    {label:" Software Engineer", value:"SOFTWARE_ENGINEER"},
    {label:" Team Lead", value:"TEAM_LEAD"},
    {label:" Quality Analyst", value:"QUALITY_ANALYST"},
    {label:" Senior Quality Analyst", value:"SENIOR_QUALITY_ANALYST"},
    {label:" Lead Quality Analyst", value:"LEAD_QUALITY_ANALYST"},
    {label:" Graphics Designer", value:"GRAPHICS_DESIGNER"},
    {label:" UI UX Designer", value:"UI_UX_DESIGNER"},
    {label:" HR", value:"HR_EXECUTIVE"},
    {label:" Accountant", value:"ACCOUNTANT"},
    {label:" Content Curator", value:"CONTENT_CURATOR"},
    
    ]
export interface RegistrationFormState {
    user: any;
    hasError?: string;
    isOpen?: boolean;
    Message?: string;
    showButton?: boolean;
    userType?: any;
    userDesignation?: any;
    passwordShow: boolean;
    confirmPasswordShow: boolean;
}
interface IMap {     [key: string]: string;}
export class RegistrationForm extends Component<{}, RegistrationFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            passwordShow: false,
            confirmPasswordShow: false,
            user: {
            },
            isOpen: false,
            Message:"",
            showButton: false,
            userDesignation: userDesignation,
            userType: userType
        }
    }
    private updateFormFieldValue = ( value: any,  id: any) => {
        var state = this.state;
        var user = state.user;
        if(id.name == Message.userType || id.name== Message.userDesignation ){
        user = {
            ...user,
            [id.name]: value.value,
        }}
        else{
            user = {
                ...user,
                [id]: value,
            }
        }
        this.setState({ user: user });
    };
    private validate = () => {
        if (!(this.state.user.firstName)) {
            this.setState({ hasError: Message.FirstName_error });
            return false;
        }
        if (!Utils.validateName(this.state.user.firstName)) {
            this.setState({ hasError: Message.FirstName_Validation_error });
            return false;
        }
        if (!(this.state.user.lastName)) {
            this.setState({ hasError: Message.LastName_error });
            return false;
        }
        if (!Utils.validateName(this.state.user.lastName)) {
            this.setState({ hasError: Message.LastName_Validation_error });
            return false;
        }
        if (!(this.state.user.userDesignation)) {
            this.setState({ hasError: Message.userDesignation_Validation_error });
            return false;
        }
        if (!(this.state.user.userType)) {
            this.setState({ hasError: Message.userType_Validation_error });
            return false;
        }
        if (!Utils.validateEmail(this.state.user.email)) {
            this.setState({ hasError: Message.Email_validation_error});
            return false;
        }
        if ((this.state.user.password) !== (this.state.user.password_confirmation)) {
            this.setState({ hasError: Message.Confirm_Password_validation_error });
            return false;
        }
        const user = this.state.user;
        // delete user.password_confirmation;
        this.setState({ hasError: undefined, user: user });
        return true;
    };
    private handleSubmit = (event: any) => {

        const isValid = this.validate();
        if (isValid) {
            let obj:IMap = this.state.user;
            Object.keys(obj).map(k => obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k]);
            Asf.api.post('/users/register',{... obj})
                .then((res: any) => {
             this.setState({user:"", isOpen:true, Message: res.Message, showButton: true,userDesignation:[], userType:[] });
                    
                }).catch((err:any) => {
                    if(err.exMessage){                       
                    this.setState({isOpen:true, Message: err.exMessage });
                    }
                    else{
                        this.setState({isOpen:true, Message: Message.Err_Message });
                    }
                });
        }


    };
    private home = () => {
        Asf.nav.navTo('/login');
    }

    render() {

        return (
            <div>
                <div className="card-container">
                    <Cards className="registration-card w-76 p-5 mt-4 mb-4 ml-6 mr-6">
                        {
                            this.state.hasError &&
                            <div className='error-msg errorTab p-4'>{this.state.hasError}</div>
                        }
                        <div className="card-body">
                            <div className="form-space">
                                <Label className="register-label" content="First Name" required></Label>
                                <InputField variant="outlined" name={'firstName'}
                                    value={this.state.user && this.state.user.first_name}
                                    onChange={this.updateFormFieldValue}
                                    required={true}
                                    className="register-input-field"
                                    placeholder="Enter First Name"
                                />
                            </div>
                            <div className="form-space">
                                <Label className="register-label" content="Last Name" required></Label>
                                <InputField variant="outlined" name={'lastName'}
                                    value={this.state.user && this.state.user.last_name}
                                    onChange={this.updateFormFieldValue}
                                    required={true}
                                    className="register-input-field"
                                    placeholder="Enter Last Name"
                                />
                            </div>
                            <div className="form-space">
                                <Label className="register-label" content="Designation" required></Label> 
                                <ReactSelect 
                                name='userDesignation' id="userDesignation" isMulti={false} className="register-input-field h-6 h-7"
                                    onChange={(e, name)=> this.updateFormFieldValue(e, name)}
                                options={this.state.userDesignation} placeholder="Select User Designation"/>
                            </div>
                            <div className="form-space">
                                <Label className="register-label" content="User Type" required></Label> 
                            
                            <ReactSelect 
                                name='userType' id="userType" isMulti={false} className="register-input-field"
                                    onChange={(e, name)=> this.updateFormFieldValue(e, name)}
                                options={this.state.userType} placeholder="Select User Type"/>
                            </div>
                            <div className="form-space">
                                <Label className="register-label" content="Email" required></Label>
                                <InputField variant="outlined" name={'email'}
                                    type={'email'}
                                    value={this.state.user && this.state.user.email}
                                    onChange={this.updateFormFieldValue}
                                    required={true}
                                    placeholder="Enter Email"
                                    className="register-input-field"
                                />
                            </div>
                            <div className="form-space">
                                <Label content="Password"className="register-label" required></Label>
                                <InputField variant="outlined" name={'password'}
                                    type={"password"}
                                    value={this.state.user && this.state.user.password}
                                    onChange={this.updateFormFieldValue}
                                    required={true}
                                    showPassword={this.state.passwordShow}
                                    handleClickShowPassword={()=>this.handleClickShowPassword("password")}
                                    placeholder="Enter Password"
                                    className="register-input-field"
                                />
                            </div>
                            <div className="form-space">
                                <Label content="Confirm Password" className="register-label" required></Label>
                                <InputField variant="outlined" name={'password_confirmation'}
                                    type={"password"}
                                    value={this.state.user && this.state.user.password_confirmation}
                                    onChange={this.updateFormFieldValue}
                                    showPassword={this.state.confirmPasswordShow}
                                    handleClickShowPassword={()=>this.handleClickShowPassword("password_confirmation")}
                                    required={true}
                                    placeholder="Confirm Password"
                                    className="register-input-field"
                                />
                            </div>
                            <Button
                                onClick={this.handleSubmit}
                                variant="contained"
                                btnColor="primary"
                                className="login_btn button-color mt-3 w-10 float-right"
                                type={'submit'}
                            >{Message.Sign_Up}
                            </Button> 
                            {/* <Button
                                onClick={this.handleCancel}
                                variant="contained"
                                className="mb-4 mr-2 mt-3 w-10 float-right"
                            >{Message.Cancel_Button}
                            </Button> */}
                        </div>
                    </Cards>
                </div>
            {this.Message()}
        </div>
        )
    }
    private handleClickShowPassword = (InputField: string) => {
        if(InputField === "password"){
            this.setState({ passwordShow:!this.state.passwordShow});
        }
        else{
            this.setState({ confirmPasswordShow:!this.state.confirmPasswordShow});
        }
      }
    private Message = () =>{
        return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
                  action={<div>
                  {/* <span> {(this.state.showButton)?   
                  <Button className="sign-in-button" variant="outlined" onClick={this.handleClick}>{Message.SignIN}</Button>:null } </span>  */}
                  <IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>
                </div>}
                 open={this.state.isOpen} autoHideDuration={3000} message={this.state.Message}/>
      } 
     
      private handleClose = () =>{
          this.setState({isOpen:false,Message:"", showButton: false, userDesignation:userDesignation, userType:userType});
      } 
      private handleClick = () => {

        Asf.nav.navTo("/login");
      }
}

