import { Button, Cards, DataGrid, DropDown, InputField, Label, ModalComponent, ReactSelect, Spinner,  TypographyComponent, Snackbar, Span, ButtonLoader, Form, FormInput, DialogBox} from "@my-org/my-monorepo-ui-lib";
import React, { Component } from "react";
import {Ticket} from "../../models/Tickets";
import {Asf, Utils} from "@my-org/inno-services";
import "./CreateTicket.scss";
import { Message } from "../../common";
import IconButton from '@material-ui/core/IconButton';
import { FaTimes } from 'react-icons/fa';
import { Common } from "../../common/common";

export interface ICreateTicketState {
    btnloader:boolean;
    ticketdetail?:Ticket;
    priority: any;
    department: any;
    res: any;
    hasError: string;
    error: any;
    isOpen: boolean;
    errMessage: string;
    Message?: string;
    showButton?: boolean;
    open:boolean;
    assetListSNA: any;
    assetListADM: any;
    btnDisable?: boolean;
}
export class CreateTicket extends Component<{}, ICreateTicketState> {

    constructor(props:any) {
        super(props);

        this.state = {
            hasError: "",
            error: {},
            btnloader: false,
            btnDisable: false,
            ticketdetail:{},
            priority: [
                {value: "High", label: "High"},
                {value: "Medium", label: "Medium"},
                {value: "Low", label: "Low"},
            ],
            department: [
                {value: "HR", label: "HR"},
                {value: "ADMIN", label: "ADMIN"},
                {value: "SNA", label: "SNA"},
            ],
            assetListSNA:[
                {value: "LAP", label: "LAPTOP"},
                {value: "LAPCHG", label: "LAPTOP CHARGER"},
                {value: "KB", label: "KEYBOARD"},
                {value: "MM", label: "MONITOR"},
                {value: "MS", label: "MOUSE"},
                {value: "CPU", label: "CPU"},
                {value: "MOB", label: "MOBILE"},
                {value: "NW", label: "NETWORK"},
                {value: "ADP", label: "ADOPTER"},
                {value: "PRN", label: "PRINTER"},
                {value: "OTH", label: "OTHER"},
            ],
            assetListADM: [
                {value: "PB", label: "PINBOARD"},
                {value: "WB", label: "WHITEBOARD"},
                {value: "CH", label: "CHAIR"},
                {value: "CD", label: "CUBICAL DESK"},
            ],
            res: {},
            errMessage: "",
            isOpen: false,
            open:false,
            Message:"",
            showButton: false,
        };
    }
    public render() {
        return (
            <div>
                <form autoComplete="off">
                <Cards className="w-76 ml-6 mr-6 pl-14 pr-14">
                    {
                        this.state.hasError &&
                        <div className="error-msg error-tab">{this.state.hasError}
                        </div>
                    }
                    <div className="title-div form-row">
                    <span className="form-label label float-left">{`Title `}<span className="requiredfield">*</span></span>
                    <span className="row-data inputfield">
                            <InputField name={"title"}
                                helperText={this.state.error.titleError}
                                className="title w-46"
                                variant="outlined"
                                value={this.state.ticketdetail && this.state.ticketdetail.title}
                                placeholder="Title"
                                onChange={this.updateFormFieldValue}
                                required={true} />
                    </span>
                    </div>

                    <div className="in-line form-row"><span className="form-label label float-left">{`Department `}<span className="requiredfield">*</span></span>
                                <ReactSelect 
                                    name="department" isMulti={false}
                                    className="chip-width inputfield w-46"
                                    placeholder="Department"
                                    onChange={this.updateReactSelect}
                                    options={this.state.department}
                                    required={true}
                                    helperText={this.state.error.departmentError}/>
                    </div>

                    <div className="in-line form-row"><span className="form-label label float-left">{`Priority `}<span className="requiredfield">*</span></span>
                            <ReactSelect 
                            name='priority' isMulti={false}
                            className="chip-width inputfield w-46"
                            placeholder="Priority"
                            onChange={this.updateReactSelect}
                            options={this.state.priority}
                            required={true}
                            helperText={this.state.error.PriorityError}/>
                            {/* <p className="MuiFormHelperText-root.Mui-error MuiFormHelperText-contained">hey</p> */}
                    </div>

                    <div className="form-row"><span className="form-label label float-left"
                            >{`Hall No. `}<span className="requiredfield">*</span></span>
                        <span className="row-data inputfield float-left">
                                <InputField name={'hall_no'} variant="outlined" className="title w-46" value={this.state.ticketdetail && this.state.ticketdetail.hall_no} placeholder="Hall Number Enter Only Numbers Ex- 1, 2 or 3 " onChange={this.updateFormFieldValue} required={true}
                                helperText={this.state.error.hallNoError}/>
                        </span>
                    </div>
                    <div className="form-row"><span className="form-label label float-left"
                            >{`Desk No `}<span className="requiredfield">*</span></span>
                        <span className="row-data inputfield float-left">
                                <InputField name={'desk_no'} variant="outlined" className="title w-46" value={this.state.ticketdetail && this.state.ticketdetail.desk_no} placeholder="Desk Number Ex- 1, 23, 15" onChange={this.updateFormFieldValue} required={true} 
                                helperText={this.state.error.deskNoError}/>
                        </span>
                    </div>

                    {((this.state.ticketdetail && this.state.ticketdetail.department === (Message.Admin.SNA)) || (this.state.ticketdetail && this.state.ticketdetail.department === ("ADMIN"))) ?
                    <div className="form-row assetid"><Label className="form-label label float-left"
                                content={`Asset Id `}/>
                        <span className="mt-4">
                        <InputField name={'inno'} variant="outlined" className="title w-10" value={"INNO"}/>
                       </span>
                       <span className="mt-4 ml-4">
                        <InputField name={'dep'} variant="outlined" className="title w-10" value={this.state.ticketdetail && this.state.ticketdetail.department} placeholder="Department"/>
                       </span>
                       <ReactSelect 
                            name='asset' isMulti={false}
                            className="w-10 mt-4 ml-4"
                            placeholder="Asset"
                            onChange={this.updateReactSelect}
                            options={this.state.ticketdetail && this.state.ticketdetail.department === Message.Admin.SNA? this.state.assetListSNA:this.state.assetListADM} helperText={this.state.error.assetError}
                        />
                        <InputField name={'asset_no'} variant="outlined" className="w-10 mt-4 ml-4" value={this.state.ticketdetail && this.state.ticketdetail.asset_no} placeholder="Asset Number" onChange={this.updateFormFieldValue} required={true}  helperText={this.state.error.assetNoError}/>
                    </div>
                    :undefined}
                    <div className="form-row">
                    <span className="form-label label float-left">{`Description `}<span className="requiredfield">*</span></span>
                        <span>
                                <InputField name={'description'}  multiline={true} rowsMax={2} variant="outlined" value={this.state.ticketdetail && this.state.ticketdetail.description} placeholder="Description"
                                    required={true} className="input-description inputfield margin-dis w-46"
                                    onChange={this.updateFormFieldValue} 
                                    helperText={this.state.error.descriptionError}/>
                        </span>
                    </div>
                    <div className="footer-row form-rows">
                        
                        <Button
                            name="Cancel"
                            onClick={this.cancel}
                            className="wms-button mr-4"
                            variant="contained"
                        >Cancel
                        </Button>
                        <Button
                            name={"Submit"}
                            onClick={this.state.btnDisable?undefined:this.submit}
                            className="wms-button button-color mr-3"
                            variant="contained"
                            btnColor="primary"
                        >{this.state.btnloader?
                            <ButtonLoader type={Common.loadingType} className="">{Message.loading}</ButtonLoader>:`Save`}
                        </Button>
                    </div>
                    </Cards>
                </form>
                    {this.Message()}
                    {this.showDialogBox()}
            </div>
    );}
    private showDialogBox = () =>{
        return <DialogBox className="feedbackform-dialogBox"
          onClick1 = {this.handleYes} onClick2 = {this.handleNo} 
          open={this.state.open} title={Message.Confirmation}
          maxWidth={"xs"} buttonClass="inbox-dialog-button ml-3 mr-1 button-color" fullWidth={true} 
          showDefaultButton={true} content={Message.reopen_confirm}
          defaultButtonVariant="contained" >
          </DialogBox>
      }
      private handleYes = () =>{
        this.setState({open: false});
        this.submit("re-open");
       }
      private handleNo = () =>{
          this.setState({open: false});
    }
    private Message = () =>{
    return (<Snackbar css={this.state.showButton?"snackbarSuccess":"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
                action={<div>
                <span> {(this.state.showButton)?
                <span>
                <Button className="sign-in-button mr-2" variant="outlined" onClick={()=>Asf.nav.navTo("/ticket/create-ticket")}>{"Yes"}</Button>
                <Button className="sign-in-button" variant="outlined" onClick={()=>Asf.nav.navTo("/ticket")}>{"No"}</Button></span>
                :<IconButton key="close" color="inherit"  onClick={this.handleSnackbarClose}><FaTimes/></IconButton> } </span> 
            </div>}
                open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage?this.state.errMessage:Message.Ticket_save_message}/>);
      }
    private handleSnackbarClose = () =>{
        this.setState({isOpen:false,errMessage:""});
    }
    private validate = (ticketdetail:any) => {
        let error = this.state.error && this.state.error; 
        if (Utils.requiredValidation(ticketdetail && ticketdetail.title ? ticketdetail.title.toString().trim() : ticketdetail.title)) {
            error = {
                titleError : Message.Ticket_title,
            }
        }
        else if (!Utils.wordLimit(ticketdetail && ticketdetail.title ? ticketdetail.title.toString().trim() : ticketdetail.title)) {
            error.titleError = Message.Session_title_validation_error;
        }else{
            error.titleError = "";
        }

        if (Utils.requiredValidation(ticketdetail && ticketdetail.department)) {
            error.departmentError = Message.Ticket_department;
        }else{
            error.departmentError = "";
        }

        if (Utils.requiredValidation(ticketdetail && ticketdetail.priority)) {
            error.PriorityError = Message.Ticket_priority;
        }else{
            error.PriorityError = "";
        }

        if (Utils.requiredValidation(ticketdetail && ticketdetail.hall_no)) {
            error.hallNoError = Message.Ticket_hallno_required;
        }
        else if (!Utils.validationHallNo(ticketdetail && ticketdetail.hall_no ? ticketdetail.hall_no.trim():ticketdetail.hall_no)) {
            error.hallNoError = Message.Ticket_hallno;
        }else{
            error.hallNoError = "";
        }

        if (Utils.requiredValidation(ticketdetail && ticketdetail.desk_no)) {
            error.deskNoError = Message.Ticket_deskno_required;
        }
        else if (!Utils.validationThreedigit(ticketdetail && ticketdetail.desk_no ? ticketdetail.desk_no.trim():ticketdetail.desk_no)) {
            error.deskNoError = Message.Ticket_deskno;
        }else{
            error.deskNoError = "";
        }

        if (this.state.ticketdetail && this.state.ticketdetail.asset_no ? this.state.ticketdetail.asset_no.toString().trim(): ticketdetail.asset_no){
            if (!(ticketdetail && ticketdetail.asset)) {
                error.assetError = Message.Ticket_asset_required;
            }
            else if (!Utils.validationAssetId(ticketdetail && ticketdetail.asset_no.toString().trim())) {
                error.assetNoError = Message.Ticket_assect_no;
            }else{
                error.assetNoError = "";
                error.assetError = "";
            }
        }else{
            error.assetNoError = "";
            error.assetError = "";
        }

        if (Utils.requiredValidation(ticketdetail && ticketdetail.description ? ticketdetail.description.toString().trim() : ticketdetail.description)) {
            error.descriptionError = Message.Ticket_discription;
        }
        else if (!Utils.validateCharacterSize300(ticketdetail && ticketdetail.description ? ticketdetail.description.toString().trim() : ticketdetail.description)) {
            error.descriptionError = Message.Ticket_discription_length;
        }else{
            error.descriptionError = "";
        }

        this.setState({ error: error });
        for (let [key, value] of Object.entries(error)) {
            if(value !== ""){
            console.log(`${key}: ${value}`);
                return false;
            }
          }
          return true;
    }
    private submit = (params: any) => {
        const isValid = this.validate(this.state.ticketdetail);
        if (isValid) {
            if (this.state.btnloader) {
                return;
            }
            this.setState({btnloader: true , btnDisable: true});
            let ticketdetail = this.state.ticketdetail;
            let deskNo = `HALL${ticketdetail && ticketdetail.hall_no ? ticketdetail.hall_no.trim(): ""}:DESK${ticketdetail && ticketdetail.desk_no ? ticketdetail.desk_no.trim(): ""}`;
            let department = this.state.ticketdetail && this.state.ticketdetail.department === "ADMIN" ? Message.Admin.ADMIN : Message.Admin.SNA;
            let asset_id;
            let description = ticketdetail && ticketdetail.description ? ticketdetail.description.trim() : "";
            let title = ticketdetail && ticketdetail.title ? ticketdetail.title.trim() : "";
            if(ticketdetail && ticketdetail.department !== "HR") {
            if(this.state.ticketdetail && this.state.ticketdetail.asset_no ? this.state.ticketdetail.asset_no.toString().trim(): this.state.ticketdetail && this.state.ticketdetail.asset_no){
                let assetno = this.state.ticketdetail && this.state.ticketdetail.asset_no + "";
                assetno = assetno && assetno.toString().trim();
                    while (assetno && assetno.toString().trim().length < 3) assetno = "0" + assetno;
                asset_id = `INNO-${department}-${this.state.ticketdetail && this.state.ticketdetail.asset}-${assetno}`;
            }else{
                asset_id = undefined;
            }
            }
            
            ticketdetail= {
                ...ticketdetail,
                "title": title,
                "deskNo": deskNo,
                "assetId": asset_id,
                "description": description,
            }
            this.setState({ ticketdetail: ticketdetail });
            let res = Utils.localstorage("get","res");
            const authToken: any = res && res.authToken;
            const Token = {
                key: "authToken", value: authToken,
            };
            Asf.api.post("/tickets/create", { ...ticketdetail }, Token)
                .then((res: any) => {
                    if(params === "re-open"){
                        Asf.nav.navTo("/ticket");
                    }else{
                    this.setState({ticketdetail: undefined, isOpen:true, Message: res.Message, showButton: true , btnloader: false});
                    }
                }).catch((err: any) => {
                    this.setState({ hasError: err.exMessage, btnloader:false , btnDisable: false});
                });
            }
    }
    private updateFormFieldValue = (value: any, name: any, e?:any) => {
        if(!(this.state.btnDisable)){
        const state = this.state;
        let ticketdetail = state.ticketdetail;
        ticketdetail = {
            ...ticketdetail,
            [name]: value,
        };
        this.setState({ ticketdetail: ticketdetail });
    }
    }
    private updateReactSelect = (value: any, name: any ) => {
        if(!this.state.btnDisable){
        const state = this.state;
        let ticketdetail = state.ticketdetail;
        ticketdetail = {
            ...ticketdetail,
            [name.name]: value.value,
        }
        this.setState({ ticketdetail: ticketdetail });
    }
    }
    private cancel = () => {
        Asf.nav.navTo("/ticket");
    }
}