import {Asf} from "@my-org/inno-services";

export class Common {

static loadingType = "grow";

static getSession = (resp:string) => {
  
   return new Promise((resolve, reject) => {
      Asf.api.get(`/session/audience/all?feedback=${resp}`)
      .then((res:any) => {
          if(res){
             const response = Common.setResponse(res);
                 resolve(response)
             }
      }).catch((err:any) => {
         reject(err);
      })      
  });
}   

static getAllSession = () =>{
   return new Promise((resolve, reject) => {
      Asf.api.get(`/session/all`)
      .then((res:any) => {
          if(res){
             const response = Common.setResponse(res);
                 resolve(response)
             }
      }).catch((err:any) => {
         reject(err);
      })      
  });
}

static getFormattedDate = (datetime:string) => {
  
   const date = new Date(datetime); // Or your date here // 2018-12-31 17:00:00
  // Or your date here // 2018-12-31 17:00:00
          const formattedDate = date.getFullYear() + "-" 
          + (date.getMonth()<10?'0':'')+ (date.getMonth()+1) + '-'
          + (date.getDate()<10?'0':'') + date.getDate()+ " " 
          + (date.getHours()<10 ?'0':'') + date.getHours() + ":" 
          + (date.getMinutes()<10?'0':'') + date.getMinutes() + ":" 
          + (date.getSeconds()<10?'0':'') + date.getSeconds();
          return formattedDate;
   }   

static setResponse = (res:any) =>{
    let rowdata;
    let row: object[] = [];
       res.map((result: any) => {
         rowdata = {id:"", session: "", title: "", Presented: "", date: "",time: "", feedbackEnabled:false,submitPer:"",location:"",duration:""}
        const sessionData = result;
                  rowdata.session = sessionData.session.id;
                  rowdata.title = sessionData.session.title;
                  let presentedByLength = sessionData.presenters && sessionData.presenters.length;
                    for(let i=0;i<presentedByLength;i++) {
                        const presentedByData = sessionData.presenters[i] || sessionData.presenters[i]
                        rowdata.Presented+=` ${presentedByData.firstName} ${presentedByData.lastName}`;
                     }
                  rowdata.date = sessionData.session.sessionDate;
                  rowdata.time = sessionData.session.sessionTime;
                  rowdata.feedbackEnabled = sessionData.session.feedbackEnabled;
                  rowdata.submitPer = sessionData.submitPer;
                  rowdata.location = sessionData.session.location;
                  rowdata.duration = sessionData.session.duration;
                  row.push(rowdata);
                 
               });
             return row;
 }
  
}