import { Button as MButton } from "@material-ui/core";
import React from "react";
export var Button = function Button(props) {
  var btnColor = props.btnColor,
      className = props.className,
      children = props.children,
      variant = props.variant,
      _onClick = props.onClick,
      size = props.size,
      disabled = props.disabled,
      fullWidth = props.fullWidth,
      startIcon = props.startIcon,
      endIcon = props.endIcon,
      type = props.type,
      name = props.name,
      onKeyPress = props.onKeyPress;
  return React.createElement(MButton, {
    className: "wms-ui-button ".concat(className),
    disabled: disabled,
    size: size,
    color: btnColor,
    variant: variant,
    onClick: function onClick() {
      return _onClick && _onClick(btnColor);
    },
    fullWidth: fullWidth,
    startIcon: startIcon,
    endIcon: endIcon,
    type: type,
    name: name,
    onKeyPress: onKeyPress
  }, children);
};