import React from "react";
import { Asf, Utils } from "@my-org/inno-services";
import './NavbarStyles.scss';
import { FaAngleRight, FaArrowLeft, FaChevronLeft } from 'react-icons/fa';
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { Breadcrumbs } from "@my-org/my-monorepo-ui-lib";

export interface NavbarProps {
    className?: string;
    lists?: any;
    src?: string;
    altName?: string;
    onClick?: () => any;
    toggleSidebar?: () => any;
    title?: string;
    location?:any;
    navigation?:any;
    user1?:any;
    path1?:string[];
}

const handleProfile = () => {
    Asf.nav.navTo("/my-profile");
};
const SomeComponent = withRouter(props => <Navbar {...props}/>);

const Navbar: React.FC<NavbarProps> = (props: React.PropsWithChildren<NavbarProps>) => {
    let resp = Utils.localstorage("get","res");
    const {src, className, altName, toggleSidebar, path1,user1} = props;
    const _path =  props.path1 && props.path1;
    // const length = props.path1 && (props.path1.length);
    let path = _path && _path.map((path,i)=> {
        if( path !== undefined && Utils.validateAlphabets(path.replace(/-/g ,''))){
            return path;
        }
    });
    path = path && path.filter(e => e !== undefined);
    const length = path && path.length;
    const onClick =  (e:any) => {
        let path = `${e.target.innerText}`;
        path = path.replace(" ", "-");
        path = path.toLowerCase();
        {_path && _path.map((value, index) => {
            if(value === path){
                let finalpath = _path.slice(0, index+1);
                if(/\d/.test(_path[index+1])){
                    finalpath = _path.slice(0, index+2);
                }
                let str:string="";
                finalpath.map((value,index) => {
                    if( value!== "" ){
                    str = str.concat(`/${value}`);
                    }   
                })
                Asf.nav.navTo(str);
            }
          })}
    }
    return <header className="nav-header">
            <div className="nav-container display-flex m-0">
                <div className="Nav-heading font-weight-500 display-flex">
                <Breadcrumbs className="breadcrumbs-1 mt-5" separator={<FaAngleRight/>}>
                {path && path.map((path,i) => {
                   if(length){
                         if( path !== undefined && Utils.validateAlphabets(path.replace(/-/g ,''))){
                               if (i === (length-1)){
                                     return <span key={i} className="active-breadcrum breadcrums capital">{path.replace(/-/g ,' ')}</span>
                                }
                                else {
                                     return <span key={i} onClick={onClick} className="breadcrums capital cursor-pointer">{path.replace(/-/g ,' ')}</span>
                                }
                        }
                    }
                  })
                }
                    </Breadcrumbs>       
                </div>
                <ul className="inner-head display-flex m-0">
                    <li className="header-li-user ml-2 mr-2 cursor-pointer display-contents">
                        
                        <div className= "user_name mr-1 text-center" onClick={handleProfile}>{props.user1 && props.user1.firstName} {props.user1 && props.user1.lastName}
                        </div>|
                        <div className= "ml-1 text-center cursor-pointer" onClick={handleProfile}>{resp && resp.userDesignation}
                        </div>
                    </li >
                    <li className="header-li ml-2 mr-2 cursor-pointer">
                        <i className="fa fa-user-circle fa-3x" aria-hidden="true" onClick={handleProfile}></i>
                    </li >
                    <li className="header-li bar-icon ml-2 display-none" onClick={toggleSidebar} style={{cursor:'pointer'}}>
                        <i className="fa fa-bars" aria-hidden="true" ></i>
                    </li>
                </ul>
                
            </div>
    </header>;
};

const mapStateToProps = (state : any) => ({
    path1: state.NavHead.path,
    user1: state.NavHead.user
});
  
export default connect(mapStateToProps)(Navbar)