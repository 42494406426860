import { combineReducers } from "redux";
import NavHead from "./Nav-head";
import SideBar from "./SideBar";
import SessionCount from "./SessionCount";
// import UserName from "./UserName";
export default combineReducers({ NavHead: NavHead,
    SideBar: SideBar,
    SessionCount:SessionCount,
    // UserName: UserName,
 });
