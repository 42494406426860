export const Message = {
    Email_validation_error: "Invalid Email",
    Password_validation_error: "Please enter password",
    Confirm_Password_validation_error: "Confirm password doesn’t matches",
    FirstName_error: "Please enter first name",
    LastName_error: "Please enter last name",
    FirstName_Validation_error: "Please enter valid first name",
    LastName_Validation_error: "Please enter valid last name",
    userDesignation_Validation_error: "Please enter user designation",
    userType_Validation_error: "Please enter user type",
    Login_heading: "Workflow Management System",
    Login_button: "Login",
    Logout_button: "Logout",
    Session_validation_error: "Please enter session",
    Session_title_validation_error: "Title should be minimum 5 characters and should not exceed 100 characters",
    PresentedBy_validation_error: "Please select presenter",
    Date_validation_error: "Please select valid date & time",
    Time_validation_error: "Please enter time",
    Audience_validation_error: "Please select audience",
    location_validation_error: "Please select location",
    Session_duration_validation_error: "Please select duration",
    Session_created_successfully: "Session created successfully",
    Session_detail_title: "Details of the Session",
    Submit_button: "SUBMIT",
    success_Message:"Submitted successfully",
    All_feedback: "All Feedbacks",
    Next: "Next",
    no_submitted_feedback:"No feedback submitted yet",
    Submitted: "Submitted List",
    Not_submitted: "Not Submitted List",
    Provide_feedback_button: "Provide Feedback",
    Create_session_button: "Create Session",
    Do_not_have_account: "Don't have an account?",
    Register_here_button: "Register Here",
    Pending_request: "Pending Request",
    Pending_Leaves: "Pending Leaves",
    Leaves_card_footer: "The counts displayed are taken from the past 6 months",
    Calender_heading: "Calendar",
    Feedback_status: "Feedback Status",
    Ticket_title: "Please enter title",
    Ticket_department: "Please enter department",
    Ticket_priority: "Please enter priority",
    Ticket_hallno_required: "Please enter hall number",
    Ticket_deskno_required: "Please enter desk number",
    Ticket_deskno: "Please enter valid desk number",
    Ticket_hallno: "Please enter valid hall number",
    Ticket_assect_no: "Please enter valid asset number",
    Ticket_assectid: "Please enter valid assectid ex-INNO-SNA-LAP-123",
    Ticket_discription: "Please enter description",
    Ticket_discription_length: "Description should be minimum 10 characters and  should not exceed 100 characters",
    Ticket_Already_approved: "Ticket already approved",
    Ticket_Already_closed: "This ticket has been closed",
    Ticket_resolution: "Please enter resolution",
    Ticket_Already_open: "Ticket already open",
    Ticket_asset_id_required: "Please enter asset id",
    Ticket_asset_required: "Please enter asset",
    Ticket_updated: "Updated successfully",
    Ticket_comment: "Please enter comment",
    Ticket_comment_length: "Comment should be minimum 10 characters",
    Update_button:"Update",
    password_reset_message: "Check your inbox for password reset email",
    send: "Send",
    delete_session: "Do you want to delete this session ?",
    forgot_password: "forgot password",
    Confirm_password_validation: "please enter confirm password",
    password_change_message: "password reset successfully",
    Admin: {
        ADMIN: "ADM",
        HR: "HR",
        SNA: "SNA",
        EMP: "EMP",
        SNA_MASTER: "SNA_MASTER",
        ADMIN_MASTER: "ADMIN_MASTER",
        SUPER_ADMIN: "SUPER_ADMIN",
    },
    Print:"Print",
    Form_heading:"Training Feedback Form",
    Designation:"Designation",
    Training_Topic:"Training Topic",
    Trainer_Name: "Trainer Name",
    Name: "Name",
    Training_Date:"Date of Training",
    Heading: "Please indicate your impressions of the items listed below.",
    Q12: "12 What aspects of the training could be improved",
    Q13: "13 Other comments?",
    Q11: "11 How do you rate the training overall?",
    SNo: "S.No.",
    question: "Question",
    Cancel_Button:"Cancel",
    Dialog_Message: "Your feedback has been submitted successfully. Would you like to provide feedback on other sessions ?",
    Yes_Button: "yes",
    No_Button: "No", 
    Strongly_Agree: "Strongly Agree",
    Agree: "Agree",
    Neutral: "Neutral",
    Disagree: "Disagree",
    Strongly_Disagree: "Strongly Disagree",
    Closed: "Closed",
    InProgress: "Inprogress",
    New: "NEW",
    Edit_button_err_message: "Edit Unavailable",
    Closed_err_message: "This ticket has been closed",
    Registration_heading: "Registration Form",
    Sign_Up: "Save",
    Version: "Version V2.5",
    No_tickets_found: "No tickets found",
    No_sessions_found: "No session found",
    No_comments_to_display: "No comments to display",
    No_session:"There is no active session for you",
    Err_Message:"Something went wrong, please check internet connectivity or there may be some server issue",
    userType:"userType",
    userDesignation: "userDesignation",
    SignIN:"Sign In",
    Enable_message: "Do you want to enable this session ?",
    disable_message: "Do you want to disable this session ?",
    loading:"Processing...",
    Ticket_save_message:"Created successfully, Do you want to create another ?",
    FirstName: "First Name : ",
    LastName: "Last Name : ",
    Email: "Email : ",
    EditProfile: "Edit Profile",
    save: "Save",
    Confirmation: "Confirmation",
    Enter_Confirm_password:"please enter confirm password",
    Other_comments:"Other Comments?",
    reopen_confirm:"Do you want to Re-open ticket",
};

