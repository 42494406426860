import React from "react";
import {Button, Cards, Form, FormInput, TypographyComponent, Snackbar, Spinner, ButtonLoader} from "@my-org/my-monorepo-ui-lib";
import { Message } from "../../common";
import {Asf, Utils} from "@my-org/inno-services";
import {Common} from '../../common/common';
import "./CreateSession.scss";
import IconButton from '@material-ui/core/IconButton';
import { FaTimes } from 'react-icons/fa';

export interface ICreateSessionProps {
  sessionId: string;
}

export interface ICreateSessionState {
  btnloader:boolean;
  hasError: string;
  userList: any;
  sessionDetail: any;
  presentedBy: any;
  location: any;
  audience: any;
  defaultlocation:any;
  isOpen: boolean;
  errMessage: string;
  loading: boolean;
  duration: any;
  showButton: boolean;
}
export class CreateSession extends React.Component<ICreateSessionProps, ICreateSessionState> {
  constructor(props: ICreateSessionProps) {
    super(props);
    this.state = {
      showButton: false,
      btnloader: false,
      loading:true,
      hasError: "",
      errMessage: "",
      isOpen: false,
      userList: [],
      presentedBy: [],
      audience:[],
      defaultlocation:"",
      location: [{label: "Board room", value: "Board room"},
      {label: "STPI board room", value: "STPI board room"},
      {label: "Pantry ", value:"Pantry "},
      {label: "Praveen's Sir Cabin", value: "Praveen's Sir Cabin"},
      {label: "Snooze Lounge ", value:"Snooze Lounge "},
    ],
    duration:[{label: "30 min", value: "30 min"},
    {label: "60 min", value: "60 min"},
    {label: "90 min", value:"90 min"},
    {label: "120 min", value: "120 min"},],
      sessionDetail: {
          session: "",
          title: "",
          audiences: "",
          presenters: {},
          location: "",
          sessionDate: ""
        },
    };
  }

  public componentDidMount () {

    Asf.api.get('/users/all')
            .then((res:[]) => {
                const transformed = res.map(({ firstName, id ,lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
                const userList = JSON.parse(JSON.stringify( transformed ));
               
                let presentedBy = JSON.parse(JSON.stringify( userList ));
                let audience = JSON.parse(JSON.stringify( userList ));
                this.setState(
                  {userList: userList,
                  presentedBy: presentedBy,
                  audience: audience
                },()=>{this.setState({ loading: false });});
            }).catch((err:any) => {
              this.setState({errMessage:err.exMessage,isOpen: true});
            })
    if(this.props.sessionId){
      this.setState({ loading: true });
      Asf.api.get(`/session/${this.props.sessionId}`)
            .then((res:any) => {
                if(res){
                  let pre:[] = res && res.presenters;
                  let presenters = pre.map(({ firstName, id, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
                  let aud:[] = res && res.audience;
                  let audiences = aud.map(({ firstName, id, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
                  let loc = res.session.location;
                  let location = {value: loc, label: loc};
                  let duration = {value: res.session.duration, label: res.session.duration};
                  let sessionDetail = this.state.sessionDetail;
                  const presentedby = this.getFilteredUserList(audiences);
                  const audience = this.getFilteredUserList(presenters)
                  sessionDetail = {
                      ...sessionDetail,
                      title: res.session.title,
                      location: location,
                      sessionDate: `${res.session.sessionDate} ${res.session.sessionTime}`,
                      sessionTime: res.session.sessionTime,
                      presenters: presenters,
                      audiences: audiences,
                      duration: duration
                  }
                  this.setState({ sessionDetail: sessionDetail,
                    presentedBy:presentedby,
                    audience:audience,
                    defaultlocation:location},()=>{this.setState({ loading: false });});
                }
            }).catch((err:any) => {
              this.setState({errMessage:err.exMessage,isOpen: true,loading: false });
            })
    }
  }

  private validate = (values:any) => {
    if (!values.title) {
      this.setState({ hasError: Message.Ticket_title });
      return false;
    }
    if (!Utils.wordLimit(values.title)) {
      this.setState({ hasError: Message.Session_title_validation_error });
      return false;
    }
    if (!values.presenters) {
      this.setState({ hasError: Message.PresentedBy_validation_error });
      return false;
    }
    if (!values.audiences) {
      this.setState({ hasError: Message.Audience_validation_error });
      return false;
    }
    if (!values.location) {
      this.setState({ hasError: Message.location_validation_error });
      return false;
    }
    if (!values.duration) {
      this.setState({ hasError: Message.Session_duration_validation_error });
      return false;
    }
    if (!values.sessionDate) {
      this.setState({ hasError: Message.Date_validation_error });
      return false;
    }
    if (values.sessionDate) {
      let dateNow = new Date();
      if(dateNow >= values.sessionDate){
      this.setState({ hasError: Message.Date_validation_error });
      return false;
      }
    }
    return true;
  };

  private getData= (values: any, length: number ) =>{
    let data = [];
    for(let i = 0; i < length; i++) {
      data[i]=values[i].value;
    }
    if(data.length){
    return data;
    }else{
      return undefined;
    }
  }
    private handleSubmit = (values:any) => {
      const isValid = this.validate(values);
      if (isValid) {
        if (this.state.btnloader) {
          return;
      }
      this.setState({btnloader: true});
        values.sessionDate = Common.getFormattedDate(values.sessionDate);
        let i = 0;
      //   let length = values.audiences.length;
        
      //   values.audiences = this.getData(values.audiences, length);
        
      //   let presentersByLength = values.presenters.length;
      //   values.presentedBy = this.getData(values.presenters, presentersByLength);
      // values.duration = values.duration && values.duration.value;
      // values.location = values.location.value;
      // values.title = values && values.title ? values.title.trim() : values.title;

      let length = values.audiences && values.audiences.length;
        let presentersByLength = values.presenters && values.presenters.length;
        let sessionData = {
          "title": values.title && values.title.trim(),
          "presentedBy": this.getData(values.presenters, presentersByLength),
          "audiences": this.getData(values.audiences, length),
          "location": values.location && values.location.value,
          "duration": values.duration && values.duration.value,
          "sessionDate": Common.getFormattedDate(values.sessionDate),
          
        }
      Asf.api.post('/session/create',sessionData)
            .then((res:any) => {
              this.setState({isOpen:true, errMessage: res.Message, showButton: true,btnloader:false});
            }).catch((err:any) => {
              this.setState({errMessage:err.exMessage,isOpen: true, btnloader:false});
            })
    }
    }
    private handleUpdate = (values:any) => {
    const isValid = this.validate(this.state.sessionDetail);
    this.setState({btnloader: true});
    if(isValid){
       // Or your date here // 2018-12-31 17:00:00
        if(values.sessionDate === "NaN-NaN-NaN NaN:NaN:NaN" || values.sessionDate === undefined) {
          values.sessionDate = undefined;
        }else{
          // values.sessionDate = Common.getFormattedDate(values.datetime);
          const formattedDate = Common.getFormattedDate(values.sessionDate);
          values.sessionDate = formattedDate;
        }
        // let length= values.audiences && values.audiences.length;
        // values.audiences = this.getData(values.audiences, length);
        
        // let presentersLength = values.presenters && values.presenters.length;
        // values.presentedBy = this.getData(values.presenters, presentersLength);
        // values.duration = values.duration && values.duration.value;
        // values.location = this.state.sessionDetail && 
        // this.state.sessionDetail.location.value;
        // values.datetime = undefined;
        // values.title = values && values.title ? values.title.trim() : values.title;
        //   values.sessionDate = Common.getFormattedDate(values.sessionDate);
    //   let i = 0;
      
      
    //   values.audiences = this.getData(values.audiences, length);
      
    //   let presentersByLength = values.presenters.length;
    //   values.presentedBy = this.getData(values.presenters, presentersByLength);
    // values.duration = values.duration && values.duration.value;
    // values.location = values.location.value;
        let length = values.audiences && values.audiences.length;
        let presentersByLength = values.presenters && values.presenters.length;
        let sessionData = {
          "title": values.title && values.title.trim(),
          "presentedBy": this.getData(values.presenters, presentersByLength),
          "audiences": this.getData(values.audiences, length),
          "location": values.location && values.location.value,
          "duration": values.duration && values.duration.value,
          "sessionDate": values.sessionDate && Common.getFormattedDate(values.sessionDate),
          
        }
        Asf.api.patch(`/session/${this.props.sessionId}/update`,sessionData)
            .then((res:any) => {
              this.setState({hasError:Message.Ticket_updated,btnloader: false});
            }).catch((err:any) => {
              this.setState({errMessage:err.exMessage,isOpen: true,btnloader: false});
            })
    }else{
      this.setState({btnloader: false});
    }
  }
     onFieldChange = (name:string, value: any) => {
      let presentedBy = this.state.sessionDetail.presenters;
      let audiences = this.state.sessionDetail.audiences;
      if(name === "presenters"){
        presentedBy = value;
        const filteredArray = this.getFilteredUserList(value);
        this.setState({audience: filteredArray});
      }
      else if (name === "audiences"){
        audiences = value;
        const filteredArray = this.getFilteredUserList(value);
        this.setState({presentedBy: filteredArray});
      }
      const state = this.state;
      let sessionDetail = state.sessionDetail;
      sessionDetail = {
          ...sessionDetail,
          [name]: value,
      };
      this.setState({ sessionDetail: sessionDetail });
  }

    private getFilteredUserList = (value: any) => {
      let filteredArray: any;
      let userList = JSON.parse(JSON.stringify( this.state.userList ));
      if(value){
        filteredArray = userList.filter((x:any)=> {
          
            return !value.find((value: any) =>
            {
            return x.value === value.value
            })
          
        })
      } else {
        filteredArray = userList;
      }
      return filteredArray;
    }
  
  render() {
  if (this.state.loading) {
      return <Spinner className="ml-20"/> ;
  }
  else{
    return (
      <div className="create-session-container display-flex">
        {/* <TypographyComponent variant={"h5"} className='createSesionHeading mb-1 mt-5'>
        {Message.Session_detail_title}
        </TypographyComponent> */}
        
        <Cards className="create-session-card w-76 p-2 pl-14 mt-4 mb-4 ml-6 mr-6">
        {this.state.hasError && <div className="error-msg error-tab text-center w-60">{this.state.hasError}
                        </div>
                    }
        <Form onSubmit={(values:any) => {
          const val = values.values;
          {this.props.sessionId ? this.handleUpdate(val):this.handleSubmit(val)}
        }}>
        <FormInput inputClassName='reactSelect locationInput h-8' layout={1} name='title' type="text"  variant="outlined"  value={this.state.sessionDetail.title} onChange={this.onFieldChange} requiredField={true} placeholder="Title"
        label="Title  "/>
        
        <FormInput chipDiv="reactSelectDiv" inputClassName='reactSelect locationInput h-8' layout={3} name='presenters' label="Presenter" type={""} isMulti={true} requiredField={true}
         value={this.props.sessionId ? this.state.sessionDetail.presenters: undefined } onChange={this.onFieldChange} options={this.state.presentedBy} placeholder="Presenter"/>
       
        <FormInput chipDiv="reactSelectDiv" inputClassName='reactSelect locationInput h-8' layout={3} name='audiences' label="Audience" type={""} isMulti={true} requiredField={true} value={this.props.sessionId ? this.state.sessionDetail.audiences: undefined } onChange={this.onFieldChange} options={this.state.audience} placeholder="Audience"/>
       
        <FormInput chipDiv="reactSelectDiv" inputClassName='reactSelect locationInput h-8' layout={3} name='location' label="Location" type={""} isMulti={false}
        options={this.state.location} requiredField={true} onChange={this.onFieldChange} value={this.state.sessionDetail.location} placeholder="Location"/>

        <FormInput chipDiv="reactSelectDiv" inputClassName='reactSelect locationInput h-8' layout={3} name='duration' label="Duration" type={""} isMulti={false}
        options={this.state.duration} requiredField={true} onChange={this.onFieldChange} value={this.state.sessionDetail.duration} placeholder="Duration"/>
       
        <FormInput inputClassName="date-time ml-4 text-center mr-2 h-8 w-21"  layout={1} name='sessionDate' type="date & time" requiredField={true} variant="outlined" selectedDate={this.state.sessionDetail.sessionDate}
        label="Date & Time" onChange={this.onFieldChange}/>
       
        <div className="button-container">
        <Button variant="contained"  className="wms-button session-cancel-btn mt-8 mb-4 w-12" onClick={this.cancel}>{Message.Cancel_Button }</Button>
        <Button type={!this.state.isOpen?'submit':undefined} variant="contained" btnColor="primary" className="wms-button session-create-btn button-color ml-4 mt-8 mb-4 w-12">{this.props.sessionId ? 
        this.state.btnloader?
        <ButtonLoader type={Common.loadingType} className="">{Message.loading}</ButtonLoader>:`Update`:
        this.state.btnloader?
        <ButtonLoader type={Common.loadingType} className="">{Message.loading}</ButtonLoader>:`Save`}
        </Button>
        
      </div>  
        </Form>
        </Cards>
        {this.Message()}
        </div>
    );
  }
}
  private cancel = () => {
    Asf.nav.navTo("/feedback");
}
  // private ErrMessage = () =>{
  //   return <Snackbar css={"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
  //             action={<IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton>}
  //            open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage}/>
  // } 
  private Message = () =>{
    return (<Snackbar css={this.state.showButton?"snackbarSuccess":"snackbarError"}  position={{vertical: "bottom", horizontal: "center"}}
                action={<div>
                <span> {(this.state.showButton)?
                <span>
                <Button className="btn-pop mr-2" variant="outlined" onClick={()=>Asf.nav.navTo("/feedback/create-session")}>{"Yes"}</Button>
                <Button className="btn-pop" variant="outlined" onClick={()=>Asf.nav.navTo('/feedback')}>{"No"}</Button></span>
                :<IconButton key="close" color="inherit"  onClick={this.handleClose}><FaTimes/></IconButton> } </span> 
            </div>}
                open={this.state.isOpen} autoHideDuration={3000} message={this.state.errMessage?this.state.errMessage:Message.Ticket_save_message}/>);
      }
  private handleClose = () =>{
      this.setState({isOpen:false,errMessage:""});
  }
}
