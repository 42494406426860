export const FeedbackQuestions = [
    {
      id: 0,
      question: `The training met my expectations.`,
      agree: true,
    },
    {
      id: 1,
      question: `I will be able to apply the knowledge learned.`,
      neutral: true
    },
    {
      id: 2,
      question: `The training objectives for each topic were identified and followed.`,
      disagree: true
    },
    {
      id: 3,
      question: `The content was organized and easy to follow.`,
      stronglyAgree: true
    },    {
      id: 4,
      question: `The materials distributed were pertinent and useful.`,
      agree: true,
    },
    {
      id: 5,
      question: `The trainer was knowledgeable.`,
      disagree: true
    },
    {
      id: 6,
      question: `The quality of instruction was good.`,
      stronglyDisagree: true
    },
    {
      id: 7,
      question: `The trainer met the training objectives.`,
      stronglyAgree: true
    },
    {
      id: 8,
      question: `Class participation and interaction were encouraged.`,
      disagree: true
    },
    {
      id: 9,
      question: `Adequate time was provided for questions and discussion.`,
      stronglyDisagree: true
    },
  ];

    export const questions = ['Q1','Q2','Q3','Q4','Q5','Q6','Q7','Q8','Q9', 'Q10'];
  
    export const response = [
    {key: 1, value: "Excellent"},
    {key: 2, value: "Good"},
    {key:3,value:'Average'},
    {key:4,value:'Poor'},
    {key:5,value:'Very poor'}
  ];
export const FeedbackQuestions2 =[
  {
    id: 0,
    question: `How do you rate the overall training ?`,
      },
] 

export const FeedbackOptions = [
    {
      id: 0,
      option: `Strongly Agree`,
    },
    {
      id: 1,
      option: `Agree`,
    },
    {
      id: 2,
      option: `Neutral`,
     },
    {
      id: 3,
      option: `Disagree`,
    },
    {
        id: 4,
        option: `Strongly Disagree`,
    },
  ];

export const OverallFeedbackOptions = [
    {
      id: 0,
      option: `Excellent`,
    },
    {
      id: 1,
      option: `Good`,
      selected: true,
    },
    {
      id: 2,
      option: `Average`,
     },
    {
      id: 3,
      option: `Poor`,
    },
    {
        id: 4,
        option: `Very poor`,
    },
  ];
