var NavigationService =
/** @class */
function () {
  function NavigationService() {
    this.history = null;
  }

  NavigationService.prototype.setHistory = function (history) {
    this.history = history;
  };

  NavigationService.prototype.navTo = function (path) {
    this.history.push(path);
  };

  NavigationService.prototype.redirect = function (linkInfo) {
    window.location.href = linkInfo;
  };

  NavigationService.prototype.goBack = function () {
    this.history.goBack();
  };

  return NavigationService;
}();

export { NavigationService };