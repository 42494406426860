import { Snackbar as MSnackbar } from "@material-ui/core";
import React from "react";
import './SnackbarStyles.scss';
export var Snackbar = function Snackbar(props) {
  var open = props.open,
      autoHideDuration = props.autoHideDuration,
      message = props.message,
      position = props.position,
      css = props.css,
      action = props.action;
  return React.createElement(MSnackbar, {
    className: css,
    anchorOrigin: position,
    open: open,
    autoHideDuration: autoHideDuration,
    ContentProps: {
      "aria-describedby": "message-id"
    },
    action: [action],
    message: React.createElement("span", {
      id: "message-id"
    }, message)
  });
};