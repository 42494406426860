var LoggerService =
/** @class */
function () {
  function LoggerService() {}

  LoggerService.prototype.log = function (args) {
    console.log(args);
  };

  LoggerService.prototype.warn = function (args) {
    console.warn(args);
  };

  LoggerService.prototype.error = function (args) {
    console.error(args);
  };

  LoggerService.prototype.info = function (args) {
    console.info(args);
  };

  LoggerService.prototype.debug = function (args) {
    console.debug(args);
  };

  return LoggerService;
}();

export { LoggerService };