import React from 'react';
import { DataGrid, Cards, Spinner } from '@my-org/my-monorepo-ui-lib';
import {Asf, Utils} from "@my-org/inno-services";
import './SubmittedFeedbacks.scss';
import { FeedbackHeader } from '../../components';

export interface IfeedbackProps{
    sessionId: string;
}

export interface IfeedbackState{
        column:any;
        data: any;
        loading:boolean;
}

export class SubmittedFeedbacks extends React.Component<IfeedbackProps, IfeedbackState >{

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            column : [{
                headerName: "S.N.", field: "SNo",sortable: true,filter: true, width:180
            }, {
                headerName: "Name", field: "Name",sortable:false,filter: false, width:350
            }, {
                headerName: "Overall Rating", field: "Rating",sortable: false,filter: false, width:350
            }
            ],
            data: []

        }
    }

    public componentDidMount(){
        Asf.api.get(`/session/${this.props.sessionId}/feedbacks`)
        .then((res: any) => {
           let rowdata;
           let row: object[] = [];
           let sno = 1;
              res.map((result: any) => {
                rowdata = {SNo: 0, Name: "", Rating: "", session: "", id: 0}
                         rowdata.SNo = sno++;
                         rowdata.Name = result.feedbackBy.firstName+" "+result.feedbackBy.lastName;
                         rowdata.Rating = result.quesAns[10].ans;
                         rowdata.session = result.session.id;
                         rowdata.id = result.feedbackBy.id;
                         row.push(rowdata);
                        
                      });
                      this.setState({data: row},()=>this.setState({loading:false}));
        }).catch((err: any) => {
        
        });
    }

    public render() {
        if (this.state.loading) {
            return <Spinner className="ml-20"/> ;
        }
        else{
        return(
            <div className="Feedback-container display-flex h-90">
                <div className="Feedback-head-container display-flex mt-4">
                    <Cards className="Feedback-head-card">
                        <FeedbackHeader sessionId={this.props.sessionId} />
                    </Cards>
                </div>
            <div className="submitted-feedback-container h-90 mt-12 mr-6 ml-6">
            <div className="submittedFeedback-container">
                 <DataGrid className="data-grid" columns={this.state.column} rows={this.state.data}
                  onRowSelect={this.onRowSelect}  />
            </div>
            </div>
            </div>
        );
    }
}

    private onRowSelect = (selectedRows: any) => {
        const selectedRow = selectedRows && selectedRows[0];
        console.log("selectrow",selectedRow)
        if (selectedRow) {
                Asf.nav.navTo(`/feedback/report/${selectedRow.session}/submitted-feedback/${selectedRow.session}/filled-feedback-form/${selectedRow.session}/${selectedRow.id}`);
        }
    }
   
}