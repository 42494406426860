import 'whatwg-fetch';
import { Utils } from '.';

var HttpService =
/** @class */
function () {
  function HttpService(host) {
    var _this = this;

    this.sendWithToken = function (httpMethod, path, body) {
      var res = Utils.localstorage("get", "res");
      var token;

      if (res && res !== null && res != undefined) {
        token = {
          key: "authToken",
          value: res.authToken
        };
      }

      return _this.send(httpMethod, path, body, token != undefined ? [token] : []);
    };

    this.apiHost = host;
  }

  HttpService.prototype.postUser = function (path, body, headers) {
    return this.send('POST', path, body, headers);
  };

  HttpService.prototype.get = function (path, params) {
    if (params) {
      path = path + '?' + this.getParams(params);
    }

    return this.sendWithToken("GET", path);
  };

  HttpService.prototype.post = function (path, body, params) {
    if (params) {
      path = path + '?' + this.getParams(params);
    }

    return this.sendWithToken('POST', path, body);
  };

  HttpService.prototype.put = function (path, body, params) {
    if (params) {
      path = path + '?' + this.getParams(params);
    }

    console.log(path);
    return this.sendWithToken("PUT", path, body);
  };

  HttpService.prototype.patch = function (path, body, params) {
    if (params) {
      path = path + '?' + this.getParams(params);
    }

    return this.sendWithToken("PUT", path, body);
  };

  HttpService.prototype.delete = function (path) {
    return this.sendWithToken("DELETE", path);
  };

  HttpService.prototype.readBody = function (response) {
    return new Promise(function (resolve, reject) {
      if (!response.ok) {
        response.json().then(function (body) {
          var apiResp = {};
          apiResp.body = body;
          resolve(apiResp);
        });
      } else {
        response.text().then(function (body) {
          var apiResp = {};
          apiResp.body = JSON.parse(body);
          resolve(apiResp);
        });
      }
    });
  };

  HttpService.prototype.parseJSON = function (response) {
    return new Promise(function (resolve, reject) {
      resolve(response);
    });
  };

  HttpService.prototype.checkStatus = function (response) {
    if (response.body.status === "Error") {
      throw response.body;
    }

    return response.body;
  };

  HttpService.prototype.send = function (httpMethod, path, body, headers) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Credentials", "true");

    if (headers && headers.length) {
      headers.forEach(function (element) {
        myHeaders.append(element.key, element.value);
      });
    }

    var apiUrl = this.apiHost + path;
    return fetch(apiUrl, {
      method: httpMethod,
      credentials: 'include',
      headers: myHeaders,
      body: body ? JSON.stringify(body) : undefined
    }).then(this.readBody).then(this.checkStatus).then(this.parseJSON, function (error) {
      throw error;
    });
  };

  HttpService.prototype.getParams = function (params) {
    var body = new URLSearchParams();

    if (params != null) {
      Object.keys(params).forEach(function (key) {
        body.set(key, params[key]);
      });
    }

    return body.toString();
  };

  return HttpService;
}();

export { HttpService };