import React from 'react';

export class PageNotFound extends React.Component{
    render(){
        return(
            <div>
                <p>404</p>
                <p>PAGE NOT FOUND</p>
            </div>    
        )
    }
}